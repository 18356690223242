import { combineReducers } from 'redux';
import Home from "./Home";
import RestaurantList from "./RestaurantList";
import Order from "./Order";
import FoodMenuList from "./FoodMenuList";
import CartItemList from "./CartItemList";
import OrderHistory from "./OrderHistory"

const rootReducers = combineReducers({
    Home,
    RestaurantList,
    FoodMenuList,
    Order,
    CartItemList,
    OrderHistory

});

export default rootReducers;