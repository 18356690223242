import {connect} from "react-redux";
import SearchComponent from "../components/SearchComponent";
import {getOutletByTrainName, getTrainList, getTrainRouteList} from "../../core/actions/action";
import {restaurantListAction} from "../../core/actions";

let obj = {
    lists: []
};

const mapStateToProps = state => {
    return {
        restaurantList: state.RestaurantList.restaurantList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTrainList: input => {
            return getTrainList(input);
        },
        getTrainRouteList: input => {
            return getTrainRouteList(input);
        },
        getOutletByTrainName: (typeCode) => {
            dispatch(restaurantListAction(null));
            return getOutletByTrainName(typeCode)
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchComponent);
