import {connect} from "react-redux";
import DeleteOrder from "../components/DeleteOrder";
import {cancelOrderRequest} from '../../core/actions/action';

const mapStateToProps = state => {
    return {
        orderList: state.Order.orderList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        cancelOrderRequest: (orderId) => {
            return cancelOrderRequest(orderId);
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteOrder);



