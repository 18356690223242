import React, {Component} from 'react';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";

class DeleteOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingStatus: false
        }
    }

    deleteOrderRequest = (orderId) => {
        this.setState({ loadingStatus: true });
        this.props.cancelOrderRequest(orderId)
            .then((res) => {
                const sources = res.data;
                if(sources.status===true){
                    this.setState({ loadingStatus: false });
                    this.props.onDeleteFinish();
                    Toaster.create({
                        position: Position.RIGHT_TOP,
                    }).show({message: sources.message, intent:Intent.SUCCESS});
                } else {
                    Toaster.create({
                        position: Position.RIGHT_TOP,
                    }).show({message: sources.message, intent:Intent.DANGER});
                }
            }).catch((error)=> {
            const sources = error.response;
            this.setState({
                loadingStatus:false
            });
            Toaster.create({
                position: Position.RIGHT_TOP,
            }).show({message: sources.data.message, intent: Intent.DANGER});
        })
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    loading={this.state.loadingStatus === false ? false : true}
                    small={true}
                    intent="primary"
                    className="mr-3"
                    text="Yes"
                    onClick={() => {
                        this.deleteOrderRequest(this.props.orderId);
                    }}
                />
            </React.Fragment>
        );
    }
}

export default DeleteOrder;