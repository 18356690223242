import React, {Component} from 'react';
import TopBar from "../core/components/TopBar";
import OrderHistoryContainer from "./container/OrderHistoryContainer";

export default class OrderHistory extends Component {
    render() {
        return (
            <div className="container">
                <TopBar back={this.props.history}  title="Order History"/>
                <div className="clearfix"/>
				<div className="order-history mb-5 pb-5">
					<OrderHistoryContainer/>
				</div>
                <div className="clearfix"/>
            </div>
        );
    }
}