import React, {Component} from "react";
import {Button, Dialog, Classes, Tab, Tabs} from "@blueprintjs/core";
import {Auth} from "gg-react-utilities";
import moment from "moment";
import ViewOrder from "../../home/components/ViewOrder";
import DeleteOrderRequestContainer from "../container/DeleteOrderRequestContainer";
import EmptyState from "../../core/components/EmptyState";
import defaultImage from "../../images/railbhoj.svg";

class OrderHistoryComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelOrderStatus: false,
            allOrders: [],
            cancelledOrders: [],
            loading: true,
            visible: 10,
            error: false
        };
        this.loadMore = this.loadMore.bind(this);
    }
    componentDidMount() {
        if (Auth.isLoggedIn()) {
            this.props.getOrderHistoryList();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.orderList !== null) {
            this.setState({
                allOrders: nextProps.orderList.data!==undefined?nextProps.orderList.data:[],
                cancelledOrders: nextProps.cancelOrderList.data!==undefined?nextProps.cancelOrderList.data:[],
                loading:false
            });
        } else {
            this.setState({
                allOrders: nextProps.orderList.data!==undefined?nextProps.orderList.data:[],
                cancelledOrders: nextProps.cancelOrderList.data!==undefined?nextProps.cancelOrderList.data:[],
                loading:false
            });
        }
    }

    handleDeleteOrderHistory = (data) => {
        this.setState({isOpen: true, orderId: data});
    };

    handleDeleteOrderHistoryClose = () => {
        this.setState({isOpen: false});
    };

    loadMore() {
        this.setState((prev) => {
            return {visible: prev.visible + 10};
        });
    }
    addDefaultSrc(ev){
        ev.target.src = defaultImage;
    }

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="col-xl-12 col-12">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-10 col-md-11 col-sm-12 col-12">
                            <div className="row">
                                {(Auth.isLoggedIn()) ?
                                    <div className="col-12">
                                    <div className="mb-4 mt-5 text-left p-0">
                                        <Tabs
                                            animate={true}
                                            id="TabsClassic"
                                            key="horizontal"
                                            renderActiveTabPanelOnly={true}
                                            vertical={false}
                                            className="bd-tabs"
                                        >
                                            <Tab
                                                className="bd-tab"
                                                id="tab1"
                                                title="All Orders"
                                                panel={
                                                    this.state.loading == true ?
                                                        <div className="col-12 img-full mb-2">
                                                            <div className="bp3-skeleton"
                                                                 style={{
                                                                     width: "100%",
                                                                     height: "200px"
                                                                 }}>&nbsp;</div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            {this.state.allOrders.length !== 0 && this.state.allOrders !== undefined ?
                                                                <React.Fragment>
                                                                    <div className="table-responsive">
                                                                        <table className="bp3-html-table table-borderless">
                                                                            <tbody>
                                                                            {this.state.allOrders.slice(0, this.state.visible).map((res, index) => {
                                                                                    return (
                                                                                        <tr key={res.order.id}>
                                                                                            <td>
                                                                                                <div className="border-rounded">
                                                                                                    <img
                                                                                                        src={
                                                                                                            res.outlet_details.outlet_img
                                                                                                        }
                                                                                                        onError={this.addDefaultSrc}
                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="resturant-name">
                                                                                                    {res.outlet_details.outlet_name}
                                                                                                    <p className="oh-sub">
                                                                                                        {res.passenger_details.train_no} /{" "}
                                                                                                        {
                                                                                                            res.passenger_details
                                                                                                                .journey_to_from_code
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                {res.outlet_details.order_id}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="rm-wt-space">
                                                                                                    {moment(
                                                                                                        res.order.created_at
                                                                                                    ).format("DD MMM YYYY")}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    className="oh-bold rm-wt-space">
                                                                                                    Rs. {res.order.total}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="oh-status">
                                                                                                    <div className="status"
                                                                                                         style={{
                                                                                                             color:
                                                                                                             res.order_status
                                                                                                                 .order_status_color
                                                                                                         }}
                                                                                                    >
                                                                                                        {
                                                                                                            res.order_status
                                                                                                                .order_status_label
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="rm-wt-space">
                                                                                                <ViewOrder viewDetail={res}/>
                                                                                                {res.is_cancellable === true ? (
                                                                                                    <Button
                                                                                                        small={true}
                                                                                                        intent="danger"
                                                                                                        text="Cancel"
                                                                                                        onClick={() => {
                                                                                                            this.handleDeleteOrderHistory(res.order.id)
                                                                                                        }}
                                                                                                    />
                                                                                                ) : (
                                                                                                    <Button
                                                                                                        small={true}
                                                                                                        intent="danger"
                                                                                                        text="Cancel"
                                                                                                        disabled={true}
                                                                                                    />
                                                                                                )}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <center>
                                                                                {this.state.visible < this.state.allOrders.length ?
                                                                                    <Button
                                                                                        small={true}
                                                                                        intent="primary"
                                                                                        text="Show More"
                                                                                        onClick={this.loadMore}
                                                                                    />:
                                                                                    ""
                                                                                }
                                                                        </center>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <EmptyState stateType={"orderHistory"} />
                                                            }
                                                        </div>
                                                }
                                            />
                                            <Tab
                                                className="bd-tab"
                                                id="tab2"
                                                title="Cancelled Orders"
                                                panel={
                                                    this.state.loading == true ?
                                                        <div className="col-12 img-full mb-2">
                                                            <div className="bp3-skeleton"
                                                                 style={{
                                                                     width: "100%",
                                                                     height: "200px"
                                                                 }}>&nbsp;</div>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            {this.state.cancelledOrders.length !== 0 && this.state.cancelledOrders !== undefined ?
                                                                <React.Fragment>
                                                                    <div className="table-responsive">
                                                                        <table className="bp3-html-table table-borderless">
                                                                            <tbody>
                                                                            {this.state.cancelledOrders.slice(0, this.state.visible).map(
                                                                                (res, index) => {
                                                                                    return (
                                                                                        <tr key={res.order.id}>
                                                                                            <td>
                                                                                                <div className="border-rounded">
                                                                                                    <img
                                                                                                        src={
                                                                                                            res.outlet_details.outlet_img
                                                                                                        }
                                                                                                        onError={this.addDefaultSrc}
                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="resturant-name">
                                                                                                    {res.outlet_details.outlet_name}
                                                                                                    <p className="oh-sub">
                                                                                                        {res.passenger_details.train_no}{" "}
                                                                                                        /{" "}
                                                                                                        {
                                                                                                            res.passenger_details
                                                                                                                .journey_to_from_code
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                {res.outlet_details.order_id}
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="rm-wt-space">
                                                                                                    {moment(
                                                                                                        res.order.created_at
                                                                                                    ).format("DD MMM YYYY")}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    className="oh-bold rm-wt-space">
                                                                                                    Rs. {res.order.total}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="oh-status">
                                                                                                    <div className="status"
                                                                                                         style={{
                                                                                                             color:
                                                                                                             res.order_status
                                                                                                                 .order_status_color
                                                                                                         }}
                                                                                                    >
                                                                                                        {
                                                                                                            res.order_status
                                                                                                                .order_status_label
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="rm-wt-space">
                                                                                                <ViewOrder viewDetail={res}/>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <center>
                                                                                {this.state.visible < this.state.cancelledOrders.length ?
                                                                                    <Button
                                                                                        small={true}
                                                                                        intent="primary"
                                                                                        text="Show More"
                                                                                        onClick={this.loadMore}
                                                                                    />:
                                                                                    ""
                                                                                }
                                                                        </center>
                                                                    </div>
                                                                </React.Fragment>
                                                                :
                                                                <EmptyState stateType={"cancelOrderHistory"} />
                                                            }
                                                        </div>
                                                }
                                            />
                                        </Tabs>
                                    </div>
                                </div>
                                    :
                                    <EmptyState stateType={"orderHistory"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog isOpen={this.state.isOpen} onClick={() => this.handleDeleteOrderHistoryClose()}
                        onClose={this.closeModal} className="bp3-dialog-" title="Delete Order">
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <strong>Are you sure you want to delete this order? </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center p-s-0">
                                <DeleteOrderRequestContainer
                                    orderId={this.state.orderId}
                                    onDeleteFinish={() => {
                                        this.handleDeleteOrderHistoryClose();
                                        this.props.getOrderHistoryList();
                                    }}
                                />
                                <Button
                                    onClick={() => this.handleDeleteOrderHistoryClose()}
                                    small={true}
                                    intent="danger"
                                    text="Close"
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default OrderHistoryComponent;
