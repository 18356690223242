import React, {Component} from 'react';
import TopBar from "../core/components/TopBar";
import Cart from "./container/Cart";

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            train_no: '',
            train_name: '',
            from_code: '',
            to_code: '',
            dateOfJourney: [],
            trainId: '',
            obj: [],
            selectedCartItem:null
        };
    }

    nextPage = (route, response) => {
        this.props.history.push(route, {
            query: response
        });
    };

    componentDidMount() {

        let data = JSON.parse(localStorage.getItem('preOrderData'));
        let outletId = this.props.location.state !== undefined ? this.props.location.state.query.outletId : data.outletId;
        let selectedCartItem = this.props.location.state !== undefined ? this.props.location.state.query : data;
        selectedCartItem.cartItemsPreOrder = selectedCartItem.cartItemsPreOrder ? selectedCartItem.cartItemsPreOrder : data.cartItemsPreOrder;
        this.setState({
            loading: false,
            selectedCartItem:selectedCartItem
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log(nextProps, 'will receive');
    }


    render() {
        let data = JSON.parse(localStorage.getItem('preOrderData'));
        let outletId = this.props.location.state !== undefined ? this.props.location.state.query.outletId : data.outletId;
        return (
            <div className="container">
                <TopBar
                    selectedData={this.state.selectedCartItem}
                    backUrl={'/menu/' + outletId}
                    title="Cart"
                    {...this.props}
                />
                <div className="resturant-listbar col-xl-12">
                    <div className="row justify-content-center">
                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12">
                            {
                                this.state.loading === true ?
                                    <div className="mb-2">
                                        <div className="bp3-skeleton"
                                             style={{width: "100%", height: "100px"}}>&nbsp;</div>
                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                                            <p className="ordered-food">
                                                Ordering Food in:
                                                <span>{this.props.location.state !== undefined ? this.props.location.state.query.train_no : data.train_no} / {this.props.location.state !== undefined ? this.props.location.state.query.train_name : data.train_name}</span>
                                            </p>
                                            <p className="ordered-food">Boarding:
                                                <span>{this.props.location.state !== undefined ? this.props.location.state.query.from_code : data.from_code}</span> To
                                                <span>{this.props.location.state !== undefined ? this.props.location.state.query.to_code : data.to_code}</span>
                                            </p>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className="text-right">
                                                <p className="ordered-food">
                                                    Date of journey:
                                                    <span>{this.props.location.state !== undefined ? this.props.location.state.query.doj : data.fromCode}</span>
                                                </p>
                                                <button className="bp3-button bp3-small bp3-intent-primary"
                                                        onClick={() => {
                                                            if ('/menu/' + outletId && this.state.selectedCartItem){
                                                                this.props.history.push('/menu/' + outletId,{query:this.state.selectedCartItem});
                                                            } else {
                                                                this.props.back.goBack();
                                                            }
                                                        }}>
                                                    Add more items
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <Cart
                    back={this.props.history}
                    minimum_order={this.props.location.state !== undefined ? this.props.location.state.query.minimum_order : data.minimum_order}
                    pnr={this.props.location.state !== undefined ? this.props.location.state.query.pnr : data.pnr}
                    train_no={this.props.location.state !== undefined ? this.props.location.state.query.train_no : data.train_no}
                    train_name={this.props.location.state !== undefined ? this.props.location.state.query.train_name : data.train_name}
                    station={this.props.location.state !== undefined ? this.props.location.state.query.station : data.station}
                    station_code={this.props.location.state !== undefined ? this.props.location.state.query.station_code : data.station_code}
                    outletId={this.props.location.state !== undefined ? this.props.location.state.query.outletId : data.outletId}
                    station_name={this.props.location.state !== undefined ? this.props.location.state.query.station_name : data.station_name}
                    doj={this.props.location.state !== undefined ? this.props.location.state.query.doj : data.doj}
                    restaurant_name={this.props.location.state !== undefined ? this.props.location.state.query.restaurant_name : data.restaurant_name}
                    cartItem={this.props.location.state !== undefined ? this.props.location.state.query.cartItem : data.cartItem}
                    cartItemsPreOrder={this.props.location.state !== undefined ? this.props.location.state.query.cartItemsPreOrder : data.cartItemsPreOrder}
                    delivery_charge={this.props.location.state !== undefined ? this.props.location.state.query.delivery_charge : data.delivery_charge}
                    from_code={this.props.location.state !== undefined ? this.props.location.state.query.from_code : data.from_code}
                    to_code={this.props.location.state !== undefined ? this.props.location.state.query.to_code : data.to_code}
                    nextPage={(route, response) => {
                        this.nextPage(route, response);
                    }}
                    onCartUpdate={(items)=>{
                        let selectedCartItem = this.state.selectedCartItem;
                        selectedCartItem.cartItemsPreOrder = items;
                        selectedCartItem.cartItem = items;
                        this.setState({
                            selectedCartItem:selectedCartItem
                        })
                    }}
                    backMenuUrl={()=>{
                        let selectedCartItem = this.props.location.state !== undefined ? this.props.location.state.query : data;
                        selectedCartItem.cartItemsPreOrder = [];
                        selectedCartItem.cartItem = [];
                        localStorage.removeItem('preOrderData');
                        this.props.history.push('/menu/' + outletId,{query:selectedCartItem});
                    }}
                />
            </div>
        );
    }
}