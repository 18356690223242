import React, {Component} from "react";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from "@material-ui/core";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItems from "./MenuItems";
import _ from 'lodash';
import {numberFormat} from '../../core/components/CommonFunction';

export default class MenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSources: [],
            cartItems: [],
            value: 0,
            loading: true,
            items: [],
            selectedItems: [],
            minimum_orderValue: ""
        };
    }

    handleCount = (itemsValue) => {

        let selectedItems = this.state.selectedItems;

        itemsValue.map((res) => {
            selectedItems.push(res)
        })
        _.uniqWith(selectedItems, !_.isEqual);
        this.setState({selectedItems: itemsValue});

    };

    componentDidMount() {
        this.props.getMenuByOutletId(this.props.outletId);
        let dataSources = [];
        let items = this.state.selectedItems;
        if (this.props.foodMenuList !== null) {
            dataSources = this.props.foodMenuList.menuItems.map((obj, index) => {
                if (this.props.cartItemLists && this.props.cartItemLists.length > 0) {
                    var a = _.find(this.props.cartItemLists, {'item_id': obj.item_id});
                    if (a !== undefined) {
                        obj.quantity = a.quantity;
                    }
                }
                let selectedItems = _.filter(obj.items, (items) => {
                    var quantity = _.find(this.props.selectedCartItem, {'item_id': items.item_id});
                    if (quantity !== undefined) {
                        items.count = quantity.quantity;
                    }
                    return items;
                });
                obj.selectedItems = selectedItems;
                let filtered = _.filter(selectedItems, function (o) {
                    return o.count !== undefined || o.count > 0;
                });
                if (filtered.length > 0) {
                    filtered.map((res) => {
                        items.push(res)
                    })
                }
                obj.items = selectedItems;
                return obj;
            });

            this.setState({
                selectedItems: items,
                dataSources: dataSources
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.foodMenuList !== null) {
            let items = [];
            let dataSources = nextProps.foodMenuList.menuItems.map((obj, index) => {
                if (nextProps.cartItemLists && nextProps.cartItemLists.length > 0) {
                    var a = _.find(nextProps.viewCartItem, {'item_id': obj.item_id});
                    if (a !== undefined) {
                        obj.quantity = a.quantity;
                    }
                }
                let selectedItems = _.filter(obj.items, (items) => {
                    var quantity = _.find(this.props.selectedCartItem, {'item_id': items.item_id});
                    if (quantity !== undefined) {
                        items.count = quantity.quantity;
                    }
                    return items;
                });
                obj.selectedItems = selectedItems;
                let filtered = _.filter(selectedItems, function (o) {
                    return o.count !== undefined || o.count > 0;
                });
                if (filtered.length > 0) {
                    filtered.map((res) => {
                        items.push(res)
                    })
                }
                obj.items = selectedItems;
                return obj;
            });

            this.setState({
                selectedItems: items,
                dataSources: dataSources,
                loading: false
            });
        }
    }

    onCart(e) {
        e.preventDefault();
        let selectedItems = [];
        this.state.dataSources.map((obj, index) => {
            if (obj.selectedItems !== undefined) {
                obj.selectedItems.map((res, key) => {
                    if (res.count !== undefined || res.count > 0) {
                        selectedItems.push(res);
                    }
                })

            }
        });
        this.setState({
            selectedItems: selectedItems
        }, () => {
            if (this.state.selectedItems.length > 0) {
                let cartItem = [];
                this.state.selectedItems.map((item) => {
                    cartItem.push({
                        item_id: item.item_id,
                        quantity: item.count
                    });
                    return cartItem
                });
                let param = {
                    outlet_id: this.props.outletId,
                    items: cartItem,
                    delivery_charge: this.props.delivery_charge
                };

                this.props.getCartItems(param)
                    .then((res) => {
                        if (res.status === true) {
                            let response = {
                                outletId: this.props.outletId,
                                restaurant_name: this.props.restaurant_name,
                                doj: this.props.doj,
                                pnr: this.props.pnr,
                                train_no: this.props.train_no,
                                train_name: this.props.train_name,
                                minimum_order: this.props.minimum_order,
                                from_code: this.props.from_code,
                                to_code: this.props.to_code,
                                station: this.props.station,
                                station_name: this.props.station_name,
                                station_code: this.props.restaurant_name,
                                cartItem: cartItem,
                                cartItemsPreOrder: res.data,
                                delivery_charge: this.props.delivery_charge
                            };
                            localStorage.setItem('preOrderData', JSON.stringify(response));
                            this.props.nextPage("/cart", response);
                        } else {

                        }
                    }).catch((error) => {
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: error.message, intent: Intent.DANGER});
                    this.props.getMenuByOutletId(this.props.outletId);
                });
            } else {
                Toaster.create({
                    position: Position.TOP,
                }).show({message: "You haven't added any item in cart!", intent: Intent.DANGER});
            }
        })

    }

    renderCart() {
        let itemCount = 0;
        let totalPrice = 0;

        this.state.dataSources.map((obj, index) => {

            if (obj.selectedItems !== undefined) {
                obj.selectedItems.map((res, key) => {

                    if (res.count !== undefined || res.count > 0) {
                        itemCount = itemCount + res.count;
                        totalPrice = totalPrice + (res.count * res.base_price);
                    }
                })

            }
        });

        let button = <button className="bp3-button bp3-button-small"
                             disabled={true}
                             onClick={() => {
                                 this.onCart()
                             }}>View Cart
        </button>;
        if (totalPrice > this.props.minimum_order ? this.props.minimum_order : 0) {
            button = <button className="bp3-button bp3-button-small btn-cart"
                             disabled={false}
                             intent={Intent.PRIMARY}
                             onClick={(e) => {
                                 if (this.props.minimum_order <= totalPrice) {
                                     this.setState({loading: true});
                                     this.onCart(e)
                                 } else {
                                     Toaster.create({
                                         position: Position.TOP,
                                     }).show({message: "Please add more items!", intent: Intent.SUCCESS});
                                 }
                             }}>View Cart

            </button>
        }

        return (
            <div
                className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pr-xl-0 pr-lg-0 pr-md-0 pr-sm-0 pl-sm-0 px-s-0 pt-s-4">
                <div className="resturant-cart">
                    <div className="row">
                        <div className="col-xl-12 col-12">
                            <p className="mb-5 mt-2 cart-title">Cart Summary</p>
                            <div className="row mt-4 text-left p-0">
                                <div className="col-xl-6 col-8">
                                    <p className="mb-4 food-item">Added Food Item</p>
                                </div>
                                <div className="col-xl-6 col-4 text-right">
                                    <p className="mb-4 total-food">{itemCount}</p>
                                </div>
                            </div>
                            <hr/>
                            <div className="row mt-4 text-left p-0">
                                <div className="col-xl-6 col-6">
                                    <p className="sub-total">Sub Total</p>
                                </div>
                                <div className="col-xl-6 col-6 text-right">
                                    <p className="sub-total">{numberFormat(totalPrice)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-12 mt-4 text-right">
                            {button}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="resturant-menu">
                    <div className="">
                        {
                            this.state.loading == true ?
                                <div className="mb-2">
                                    <div className="bp3-skeleton"
                                         style={{width: "100%", height: "250px"}}>&nbsp;</div>
                                </div>
                                :
                                <div className="row">
                                    <div id="menu"
                                         className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-0 px-s-0">
                                        {this.state.dataSources ? this.state.dataSources.map((res, index) => {
                                                return (
                                                    <ExpansionPanel
                                                        expanded={res.expanded !== undefined ? !res.expanded : (index == 0 ? true : false)}
                                                        onChange={() => {
                                                            let dataSources = this.state.dataSources;
                                                            dataSources[index].expanded = res.expanded !== undefined ? !res.expanded : false;
                                                            this.setState({
                                                                dataSources: dataSources
                                                            })
                                                        }} className="border shadow-none" key={index}>
                                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                                                            <div className="menu-item-list">{res.name}</div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails>
                                                            <div className="table-responsive">
                                                                <table className="bp3-html-table  border-0">
                                                                    <thead className="thead-light">
                                                                    <tr>
                                                                        <th>Item Name</th>
                                                                        <th>Price</th>
                                                                        <th>Add Item</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    <MenuItems items={res.items}
                                                                               onSelectFilterData={(response) => {
                                                                                   let dataSources = this.state.dataSources;
                                                                                   dataSources[index].selectedItems = response;
                                                                                   this.setState({
                                                                                       dataSources: dataSources
                                                                                   })

                                                                               }}/>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                )
                                            })
                                            :
                                            <div>
                                                <h1>No Record Found</h1>
                                            </div>
                                        }
                                    </div>
                                    {this.renderCart()}
                                </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
