import React, {Component} from 'react';
import _ from "lodash";

class CartItemLists extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentWillMount() {
        if (this.props.items !== null) {
            this.setState({
                items: this.props.items,
                dataSources: this.props.items
            })
        } else {
            this.setState({
                items: [],
                dataSources: []
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
    }

    handleCountChange = () => {
        let data = _.filter(this.props.items.items, (sub) => {
            return sub.quantity !== undefined && sub.quantity > 0;
        });
        this.props.onSelectFilterData(data)
            .then((res)=>{
            }).catch((error)=>{
        });
    };

    render() {
        if (this.props.items !== undefined) {
            if (!this.props.items.length === 0) {
                return (null)
            }
        }
        return this.props.items.items.map((item, key) => {
            return (
                <React.Fragment key={key}>
                    <tr>
                        <td>
                            <div className="food-name">
                                {item.item_name ? item.item_name : ""}
                                <p>{item.description ? item.description : ""}</p>
                            </div>
                        </td>
                        <td>
                            <div className="food-name">
                                ₹ {parseFloat(item.base_price).toFixed(2)}
                                <p className="oh-sub">Time : {item.opening_time} - {item.closing_time}</p>
                            </div>
                        </td>
                        <td>
                            <div className="clearfix"/>
                                <div className="bp3-control-group">
                                    <button className="bp3-button bp3-decrease"
                                            type={"Cart"}
                                            onClick={() => {
                                                let obj = item;
                                                let currentCount = obj.quantity !== undefined ? obj.quantity : 0;
                                                if (currentCount > 0) {
                                                    obj.quantity = parseInt(currentCount) - 1;
                                                    this.setState({
                                                        items: obj
                                                    }, () => {
                                                        this.handleCountChange();
                                                    })
                                                }
                                            }}
                                    >
                                        <span className="bp3-icon bp3-icon-minus"/>
                                    </button>
                                    <div className="bp3-input-group bp3-input-counter-group">
                                        <input
                                            type="text"
                                            className="bp3-input bp3-input-counter text-center"
                                            value={item.quantity ? item.quantity : 0}
                                        />
                                    </div>
                                    <button className="bp3-button bp3-increase"
                                            type={"Cart"}
                                            onClick={() => {
                                                let obj = item;
                                                let currentCount = obj.quantity !== undefined ? obj.quantity : 0;
                                                if (currentCount >= 0) {
                                                    obj.quantity = parseInt(currentCount) + 1;
                                                    this.setState({
                                                        items: obj
                                                    }, () => {
                                                        this.handleCountChange();
                                                    })
                                                }
                                            }}
                                    >
                                        <span className="bp3-icon bp3-icon-plus"/>
                                    </button>
                                </div>
                        </td>
                    </tr>
                </React.Fragment>
            );
        })
    }
}

export default CartItemLists;