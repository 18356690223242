export const restaurantListAction = (sources) => {
    return {
        type: 'RESTAURANT_LIST',
        sources: sources
    }
};

export const restaurantFoodMenuAction = (sources) => {

    return {
        type: 'RESTAURANT_FOOD_MENU',
        sources: sources
    }
};

export const preOrderAction = (sources) => {

    return {
        type: 'PRE_ORDER_CART_ITEMS',
        sources: sources
    }
};

export const orderListAction = (sources) => {
    return {
        type: 'ORDER_LIST',
        sources: sources
    }
};

export const cancelOrderListAction = (sources) => {
    return{
        type:'CANCEL_ORDER',
        sources:sources
    }
};

export const placeOrderAction = (sources) => {
    return {
        type: "PLACE_ORDER",
        sources:sources
    }
};

export const cancelOrderRequestAction = (sources) => {
    return{
        type:'CANCEL_ORDER_REQUEST',
        sources:sources
    }
};