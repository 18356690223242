import { connect } from "react-redux";
import Cart from "../components/Cart";
import {preOrderAction} from "../../core/actions";
import {getCartItems} from "../../core/actions/action";

let obj={
    cartItems:[]
};

const mapStateToProps = state => {
    return {
        cartItemLists: state.CartItemList.cartItemLists
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCartItems:(param) => {
            dispatch(preOrderAction(null));
            return getCartItems(param)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        dispatch(preOrderAction(sources.data));
                    }else{
                        dispatch(preOrderAction([]));
                    }
                    return new Promise((resolve, reject)=>{
                        resolve(true)
                    })
                })
                .catch(error => {
                    return new Promise((resolve, reject)=>{
                        reject(true)
                    })
                });
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart);
