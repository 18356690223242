import React, {Component} from 'react';
import CartItemLists from "./CartItemLists";

class CartItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSources: [],
            foodCount: 0,
            selectedItems: []
        }
    }

    componentWillMount() {
    }

    handleCount = (itemsValue) => {
        return this.props.onGetSelectData(itemsValue);
    };

    render() {

        if (this.state.dataSources === "" || this.state.dataSources === null) {
            return "record not found";
        }
        return (
            <div id="menu"
                 className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12 pl-xl-0 pl-lg-0 pl-md-0 pl-sm-0 px-s-0">
                <table className="bp3-html-table border-0 pl-4">
                    <tbody>
                    <CartItemLists
                        items={this.props.dataSources}
                        onSelectFilterData={this.handleCount}
                        onNextPage={this.props.onNextPage}
                    />
                    </tbody>
                </table>
            </div>
        )
    }
}

export default CartItem;