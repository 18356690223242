import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";

import Home from '../home';
import Restaurant from '../restaurant';
import Menu from '../menu';
import Cart from '../cart';
import OrderHistory from '../orderHistory';
import CheckOut from "../checkout";
import Payment from "../Payment";
import {Notfound, Error, Maintance} from "gg-react-utilities";
import Config from "../core/Config";

export default class Root extends Component{
    render(){

        if(Config.IS_MAINTENANCE_MODE){
            return(
                <Maintance />
            );
        }

        return(
            <Switch>
                <PrivateRoute exact path="/" component={ Home } />
                <PrivateRoute exact path="/restaurant" component={ Restaurant } />
                <PrivateRoute exact path="/menu/:outletId" component={ Menu } />
                <PrivateRoute exact path="/cart" component={ Cart } />
                <PrivateRoute exact path="/checkoutDetails" component={ CheckOut } />
                <PrivateRoute exact path="/orderhistory" component={OrderHistory } />
                <PrivateRoute exact path='/payment/:status/:orderID' component={Payment}/>
                <PrivateRoute exact path='*' component={Notfound}/>
            </Switch>
        );
    }
}