import React from 'react'
import { Route } from 'react-router-dom'
import {Header,Footer, ScrollTop} from 'gg-react-utilities';
const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="railbhoj">
            <Header headerType={"dark"} />
            <Component {...props} />
            <Footer/>
            <ScrollTop/>
        </div>
    )}/>
);

export default PrivateRoute;
