import React, {Component} from 'react';
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";

class DeleteOrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader:false,
            loadingStatus:false
        }
    }

    deleteHistoryOrderRequest = (orderId) => {
        this.setState({loadingStatus:true});
        this.props.cancelOrderRequest(orderId)
            .then((res) => {
                const sources = res.data;
                if(sources.status===true){
                    this.props.onDeleteFinish();
                    this.setState({loadingStatus:false});
                    Toaster.create({
                        position: Position.RIGHT_TOP,
                    }).show({message: sources.message, intent:Intent.SUCCESS});
                }
            }).catch((error)=> {
            const sources = error.response;
            Toaster.create({
                position: Position.RIGHT_TOP,
            }).show({message: sources.message, intent: Intent.SUCCESS});
        })
    };

    render() {
        return (
            <React.Fragment>
                <Button
                    loading={this.state.loadingStatus === false ? false : true}
                    small={true}
                    intent="primary"
                    className="mr-3"
                    text="Yes"
                    onClick={() => {
                        this.deleteHistoryOrderRequest(this.props.orderId);
                        this.setState({loader:true});
                    }}
                />
            </React.Fragment>
        );
    }
}

export default DeleteOrderHistory;