import React from "react";
import Veg from "../../images/veg.svg";
import Nonveg from "../../images/nonveg.svg";
import {DirectionsBikeRounded} from '@material-ui/icons';
import {Button} from "@blueprintjs/core";

export default class RestaurantList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSources: null,
            foodCount: 0,
            doj: '',
            loading: true,
            visible: 10,
        };
        this.loadMore = this.loadMore.bind(this);
    }

    componentDidMount() {
        let search = this.props.history.location.search.substring(1);
        let searchObj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        });

        let params = '';
        if (searchObj.type === "pnr") {
            params = searchObj.type + '/' + searchObj.pnr;
            this.setState({
                type: searchObj.type,
                pnr: searchObj.pnr
            })
        } else {
            params = searchObj.type + '/' + searchObj.train + '/' + searchObj.date + '/' + searchObj.trainCode;
            this.setState({
                doj: searchObj.date,
                train: searchObj.train,
                type: searchObj.type
            })
        }

        this.props.getOutletByTrainName(params);
        // this.props.onSearch(this.props.restaurantList)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.restaurantList !== null) {
            if (nextProps.restaurantList.doj !== undefined) {
                this.setState({
                    dataSources: nextProps.restaurantList,
                    doj: nextProps.restaurantList.doj,
                    loading: false
                })
            } else {
                this.setState({
                    dataSources: nextProps.restaurantList,
                    loading: false
                })
            }
        }
    }

    toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    getRestaurantMenuList(outletId, param) {
        this.props.nextPage("/menu/" + outletId, param);
    }

    loadMore() {
        this.setState((prev) => {
            return {visible: prev.visible + 10};
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="resturant-listbar col-xl-12">
                    <div className="row justify-content-center">
                        <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12">

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                                    <p className="ordered-food">
                                        {this.state.loading == true ?
                                            <div className="mb-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                            </div>
                                            :
                                            <React.Fragment>
                                                Ordering Food in:
                                                <span> {this.state.dataSources.train_no !== undefined ? this.state.dataSources.train_no + " / " + this.state.dataSources.train_name : ""}</span>
                                            </React.Fragment>
                                        }
                                    </p>
                                    <p className="ordered-food">
                                        {this.state.loading == true ?
                                            <div className="mb-2">
                                                <div className="bp3-skeleton"
                                                     style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                            </div>
                                            :
                                            <React.Fragment>
                                                Boarding:
                                                <span>{this.state.dataSources ? this.state.dataSources.from_code : ""}</span> To <span>{this.state.dataSources ? this.state.dataSources.to_code : ""}</span>
                                            </React.Fragment>
                                        }
                                    </p>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="text-right">
                                        <p className="ordered-food">
                                            {this.state.loading == true ?
                                                <div className="mb-2">
                                                    <div className="bp3-skeleton"
                                                         style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    Date of journey:
                                                    <span>{this.state.doj}</span>
                                                </React.Fragment>
                                            }
                                        </p>
                                        <p className="ordered-food">
                                            {this.state.loading == true ?
                                                <div className="mb-2">
                                                    <div className="bp3-skeleton"
                                                         style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    <button className="bp3-button bp3-small bp3-intent-primary"
                                                            onClick={() => {
                                                                this.props.history.push('/')
                                                            }}>
                                                        Change Journey details
                                                    </button>
                                                </React.Fragment>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 col-xl-12 resturant-list">
                    <div className="clearfix"></div>
                    <div className="row">
                        <div className="col-xl-12 col-12">
                            <div className="row justify-content-center">
                                <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12">

                                    <div className="p-3 px-s-0 px-sm-0">

                                        <div className="col-12 mb-4 mt-4 text-left p-0">
                                            <p className="bp3-text-xlarge title text-center-s">Restaurant
                                                list</p>
                                        </div>
                                        {
                                            this.state.loading == true ?
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-xl-6 col-sm-6 col-12 text-left">
                                                            <p className="ordered-food">
                                                                <div className="mb-2">
                                                                    <div className="bp3-skeleton"
                                                                         style={{
                                                                             width: "100%",
                                                                             height: "150px"
                                                                         }}>&nbsp;</div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                        <div className="col-xl-6 col-sm-6 col-12 text-right">
                                                            <p className="ordered-food">
                                                                <div className="mb-2">
                                                                    <div className="bp3-skeleton"
                                                                         style={{
                                                                             width: "100%",
                                                                             height: "150px"
                                                                         }}>&nbsp;</div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <div className="row mb-4 mt-4">
                                                        {this.state.dataSources ? this.state.dataSources.lists.slice(0, this.state.visible).map((res, index) => {
                                                                return (
                                                                    <div
                                                                        className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-1"
                                                                        key={res.outlet_id}>
                                                                        <div className="resturant-card">
                                                                            <div className="card-header">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-xl-6 col-sm-7 col-8 text-left">
                                                                                        <p>
                                                                                            {this.toTitleCase(res.city)}
                                                                                            <span>({res.station_code})</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-xl-6 col-sm-5 col-4 text-right">
                                                                                        <p className="order-date">Day {res.day ? res.day : "1"} {res.time}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="resturant-body">
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                                        <div className="image-box">
                                                                                            <img
                                                                                                src={res.logo}
                                                                                                className="rounded mx-auto d-block"
                                                                                                draggable="false"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-left pl-s-0 pl-sm-0">
                                                                                        <p className="restaurant-name">
                                                                                            {this.toTitleCase(res.restaurant_name)}
                                                                                            <span>({res.opening_time}-{res.closing_time} hrs)</span>
                                                                                        </p>
                                                                                        <p className="food-time"><i
                                                                                            className="material-icons mr-2"><DirectionsBikeRounded/></i> {res.order_before} Minutes
                                                                                        </p>
                                                                                        <div className="row mb-2">
                                                                                            <div
                                                                                                className="col-xl-6 col-lg-6 col-md-6 col-sm-7 col-7 pr-sm-0 text-left">
                                                                                                <p className="order-types">Min
                                                                                                    Order
                                                                                                    ₹ {res.minimum_order}</p>
                                                                                            </div>
                                                                                            <div
                                                                                                className="col-xl-6 col-xl-6 col-lg-6 col-md-6 col-sm-5 col-5 pl-sm-0 text-right">
                                                                                                <div className="icon">
                                                                                                    {res.tags[0] === "Veg" || res.tags[0] === "Both" ?
                                                                                                        <img
                                                                                                            className="veg mr-2"
                                                                                                            src={Veg}/> : null}
                                                                                                    {res.tags[0] === "Non-Veg" || res.tags[0] === "Both" ?
                                                                                                        <img
                                                                                                            className="non-veg"
                                                                                                            src={Nonveg}/> : null}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="text-center">
                                                                                            <button
                                                                                                className="bp3-button bp3-small btn-order bp3-intent-primary"
                                                                                                onClick={() => {
                                                                                                    let response = {
                                                                                                        outletId: res.outlet_id,
                                                                                                        restaurant_name: res.restaurant_name,
                                                                                                        doj: this.state.doj,
                                                                                                        pnr: this.state.dataSources.pnr,
                                                                                                        train_no: this.state.dataSources.train_no,
                                                                                                        train_name: this.state.dataSources.train_name,
                                                                                                        from_code: this.state.dataSources.from_code,
                                                                                                        to_code: this.state.dataSources.to_code,
                                                                                                        minimum_order: Number(res.minimum_order),
                                                                                                        delivery_charge: res.delivery_charge,
                                                                                                        station: res.station,
                                                                                                        station_name: res.station_name
                                                                                                    };
                                                                                                    localStorage.setItem('preOrderData', JSON.stringify(response));
                                                                                                    this.getRestaurantMenuList(res.outlet_id, response);
                                                                                                }}
                                                                                            >Food Menu
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div>
                                                                <h2>No Records found</h2>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-12 align-content-center">
                                                            <div className="col-12 justify-content-center text-center">
                                                                {this.state.visible < this.state.dataSources.lists.length ?
                                                                    <Button
                                                                        small={true}
                                                                        intent="primary"
                                                                        text="Show More"
                                                                        onClick={this.loadMore}
                                                                    /> :
                                                                    ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};
