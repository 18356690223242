let initialState = {
    foodMenuList:null
};
const FoodMenuList = (state = initialState, action) => {

    switch (action.type) {
        case 'RESTAURANT_FOOD_MENU':
            return {...state, foodMenuList: action.sources};
        default:
            return state
    }
};

export default FoodMenuList;