import React, {Component} from 'react';
import TopBar from "../core/components/TopBar";
import MenuList from "./container/MenuList";

export default class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            train_no: '',
            train_name: '',
            from_code: '',
            to_code: '',
            dateOfJourney: '',
            trainId: ''
        };
    }

    nextPage = (route, response) => {
        this.props.history.push(route, {
            query: response
        });
    };

    componentWillMount() {
    }

    componentDidMount() {
        if (this.props.location.state !== undefined) {
            this.setState({
                train_no: this.props.location.state.query.train_no,
                train_name: this.props.location.state.query.train_name,
                from_code: this.props.location.state.query.from_code,
                to_code: this.props.location.state.query.to_code,
                dateOfJourney: this.props.location.state.query.doj,
                minimum_order: this.props.location.state.query.minimum_order,
                delivery_charge: this.props.location.state.query.delivery_charge,
                loading: false,
            });
        } else {
            let data = JSON.parse(localStorage.getItem('preOrderData'));
            this.setState({
                train_no: data.train_no,
                train_name: data.train_name,
                from_code: data.from_code,
                to_code: data.to_code,
                dateOfJourney: data.doj,
                minimum_order: data.minimum_order,
                delivery_charge: data.delivery_charge,
                loading: false,
            });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log(nextProps, nextContext, 'will receive props');
    }

    render() {
        if (this.props.location.state !== undefined) {
            if (Number(this.props.match.params.outletId) !== Number(this.props.location.state.query.outletId)) {
                localStorage.removeItem('preOrderData');
            }
        }
        let data = JSON.parse(localStorage.getItem('preOrderData'));
        try {
            return (
                <div className="container">
                    <TopBar back={this.props.history} title="Add Menu"/>
                    <div className="resturant-listbar col-xl-12">
                        <div className="row justify-content-center">
                            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-12 col-12">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-left">
                                        <p className="ordered-food">
                                            {this.state.loading == true ?
                                                <div className="mb-2">
                                                    <div className="bp3-skeleton"
                                                         style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    Ordering Food in:
                                                    <span> {this.state.train_no ? this.state.train_no : ''} / {this.state.train_name ? this.state.train_name : ''}</span>
                                                </React.Fragment>
                                            }
                                        </p>
                                        < p className="ordered-food">
                                            {this.state.loading == true ?
                                                <div className="mb-2">
                                                    <div className="bp3-skeleton"
                                                         style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    Boarding:
                                                    <span>{this.state.from_code ? this.state.from_code : ''}</span> To <span>{this.state.to_code ? this.state.to_code : ''}</span>
                                                </React.Fragment>
                                            }
                                        </p>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div className="text-right">
                                            <p className="ordered-food">
                                                {this.state.loading == true ?
                                                    <div className="mb-2">
                                                        <div className="bp3-skeleton"
                                                             style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        Date of journey:
                                                        <span>{this.state.dateOfJourney ? this.state.dateOfJourney : ""}</span>
                                                    </React.Fragment>
                                                }
                                            </p>
                                            <p className="ordered-food">
                                                {this.state.loading == true ?
                                                    <div className="mb-2">
                                                        <div className="bp3-skeleton"
                                                             style={{width: "100%", height: "100%"}}>&nbsp;</div>
                                                    </div>
                                                    :
                                                    <React.Fragment>
                                                        <button
                                                            className="bp3-button bp3-small bp3-intent-primary"
                                                            onClick={() => {
                                                                let searchObj = {};
                                                                if (this.props.location.state.query.pnr) {
                                                                    searchObj.pnr = this.props.location.state.query.pnr;
                                                                    searchObj.type = "pnr";
                                                                } else {
                                                                    searchObj.date = this.props.location.state.query.doj;
                                                                    searchObj.type = "train";
                                                                    searchObj.train = this.props.location.state.query.train_no;
                                                                }

                                                                var str = '?' + Object.keys(searchObj).map((key) => {
                                                                    return key + '=' + searchObj[key];
                                                                }).join('&');

                                                                this.props.history.push({
                                                                    pathname: '/restaurant',
                                                                    search: str
                                                                });
                                                            }}>
                                                            Change Restaurant details
                                                        </button>
                                                    </React.Fragment>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 col-xl-12 mb-5">
                        <MenuList
                            selectedCartItem={this.props.location.state !== undefined && this.props.location.state.query.cartItem ? this.props.location.state.query.cartItem : []}
                            outletId={this.props.location.state !== undefined ? this.props.location.state.query.outletId : data.outletId}
                            minimum_order={this.props.location.state !== undefined ? this.props.location.state.query.minimum_order : data.minimum_order}
                            pnr={this.props.location.state !== undefined ? this.props.location.state.query.pnr : data.pnr}
                            train_no={this.props.location.state !== undefined ? this.props.location.state.query.train_no : data.train_no}
                            train_name={this.props.location.state !== undefined ? this.props.location.state.query.train_name : data.train_name}
                            station={this.props.location.state !== undefined ? this.props.location.state.query.station : data.station}
                            station_code={this.props.location.state !== undefined ? this.props.location.state.query.station_code : data.station_code}
                            station_name={this.props.location.state !== undefined ? this.props.location.state.query.station_name : data.station_name}
                            doj={this.props.location.state !== undefined ? this.props.location.state.query.doj : data.doj}
                            restaurant_name={this.props.location.state !== undefined ? this.props.location.state.query.restaurant_name : data.restaurant_name}
                            delivery_charge={this.props.location.state !== undefined ? this.props.location.state.query.delivery_charge : data.delivery_charge}
                            from_code={this.props.location.state !== undefined ? this.props.location.state.query.from_code : data.from_code}
                            to_code={this.props.location.state !== undefined ? this.props.location.state.query.to_code : data.to_code}
                            onSearch={parameter => {
                                this.setState(() => {
                                    this.onSearch(parameter);
                                });
                            }}
                            nextPage={(route, param) => {
                                this.nextPage(route, param)
                            }}
                        />
                    </div>
                </div>
        );
        } catch (e) {
            return (
        {/*<Error />*/}
            )

        }
        }
        }
