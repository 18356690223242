import React, {Component} from "react";
import {Button, Classes, Dialog} from "@blueprintjs/core";
import moment from "moment";
import {Link} from "react-router-dom";
import {Auth} from "gg-react-utilities";
import ViewOrder from "../components/ViewOrder";
import DeleteOrder from "../container/DeleteOrderContainer";
import EmptyState from "../../core/components/EmptyState";
import defaultImage from "../../images/railbhoj.svg";

class OrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            dataSources: [],
            loading: true,
            orderId: "",
            max_count: 5
        };
    }

    componentDidMount() {
        if (Auth.isLoggedIn()) {
            this.props.getOrderList();
        }
        this.setState({loading: false});
        localStorage.removeItem('preOrderData');
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.orderList !== null) {
            this.setState({
                dataSources: nextProps.orderList.data !== undefined ? nextProps.orderList.data : [],
                loading: false
            });
        } else {
            this.setState({
                dataSources: nextProps.orderList.data !== undefined ? nextProps.orderList.data : [],
                loading: false
            });
        }
    }

    handleDeleteOrder = (data) => {
        this.setState({isOpen: true, orderId: data});
    };

    handleDeleteOrderClose = () => {
        this.setState({isOpen: false});
    };

    addDefaultSrc(ev) {
        ev.target.src = defaultImage;
    }

    render() {
        let count = 0;
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="col-xl-12 col-12">
                    <div className="row mb-2 justify-content-center">
                        {
                            this.state.loading === true ?
                                <div className="col-12 img-full mb-2 mt-5">
                                    <div className="bp3-skeleton"
                                         style={{width: "100%", height: "200px"}}>&nbsp;</div>
                                </div>
                                :
                                <div className="col-xl-11">
                                    {this.state.dataSources.length !== 0 && this.state.dataSources !== undefined ?
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="mb-4 mt-5 text-left p-0">
                                                        <h1 className="bp3-text-xlarge heading text-center-s">Order
                                                            History</h1>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="table-responsive">
                                                    <table className="bp3-html-table table-borderless">
                                                        <tbody>
                                                        {this.state.dataSources.map((res, index) => {
                                                            count++;
                                                            if (count <= this.state.max_count) {
                                                                return (
                                                                    <tr key={res.order.id}>
                                                                        <td>
                                                                            <div className="border-rounded">
                                                                                <img
                                                                                    src={res.outlet_details.outlet_img}
                                                                                    alt=""
                                                                                    onError={this.addDefaultSrc}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="mr-1">
                                                                                {res.outlet_details.outlet_name}
                                                                                <p className="oh-sub">
                                                                                    {res.passenger_details.train_no} /{" "}
                                                                                    {res.passenger_details.journey_to_from_code}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="mr-1">
                                                                                <p>{res.outlet_details.order_id}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="mr-1 rm-wt-space">
                                                                                <p>
                                                                                    {moment(res.order.created_at).format(
                                                                                        "DD MMM YYYY"
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                className="mr-1 oh-bold rm-wt-space">
                                                                                <p>Rs. {res.order.total}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="mr-1 oh-status">
                                                                                <p
                                                                                    className="status"
                                                                                    style={{
                                                                                        color: res.order_status.order_status_color
                                                                                    }}
                                                                                >
                                                                                    {res.order_status.order_status_label}
                                                                                </p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="rm-wt-space">
                                                                            <ViewOrder viewDetail={res}/>
                                                                            {res.is_cancellable === true ? (
                                                                                <Button
                                                                                    small={true}
                                                                                    intent="danger"
                                                                                    text="Cancel"
                                                                                    onClick={() => {
                                                                                        this.handleDeleteOrder(res.order.id)
                                                                                    }}
                                                                                />
                                                                            ) : (
                                                                                <Button
                                                                                    small={true}
                                                                                    intent="danger"
                                                                                    text="Cancel"
                                                                                    disabled={true}
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            }
                                                        })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-12 text-center">
                                                    <div className="col-12 text-center">
                                                        <Link to="/orderhistory" className="view-all">View
                                                            All</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        :
                                        <EmptyState stateType={"home"}/>
                                    }
                                </div>
                        }
                    </div>
                </div>
                <Dialog isOpen={this.state.isOpen} onClick={this.handleDeleteOrderClose}
                        className="bp3-dialog-" title="Delete Order">
                    <div className={Classes.DIALOG_BODY}>
                        <div className="row mb-5">
                            <div className="col-12 text-center">
                                <strong>Are you sure you want to cancel order? </strong>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center p-s-0">
                                <DeleteOrder
                                    orderId={this.state.orderId}
                                    onDeleteFinish={() => {
                                        this.handleDeleteOrderClose();
                                        this.props.getOrderList()
                                    }}
                                />
                                <Button
                                    onClick={() => this.handleDeleteOrderClose()}
                                    small={true}
                                    intent="danger"
                                    text="Close"
                                />
                            </div>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

export default OrderHistory;
