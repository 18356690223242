import { connect } from "react-redux";
import RestaurantList from '../components/RestaurantList';
import {toast} from "react-toastify";
import {getOutletByTrainName} from "../../core/actions/action";
import {restaurantListAction} from "../../core/actions";
let obj={
    lists:[]
};

const mapStateToProps = state => {
    return {
        restaurantList:state.RestaurantList.restaurantList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOutletByTrainName:(typeCode) => {
            dispatch(restaurantListAction(null));
            return getOutletByTrainName(typeCode)
                .then((res)=>{
                    const sources = res.data;
                    if(sources.status===true){
                        if(sources.data.pnr){
                            if(sources.data.pnr_status===true){
                                dispatch(restaurantListAction(sources.data))
                            }
                        }else{
                            dispatch(restaurantListAction(sources.data))
                        }
                    }else{
                        if(sources.data.pnr_status===false){
                            toast.error("Entered PNR number is expired!", {
                                position: toast.POSITION.TOP_CENTER
                            });
                            dispatch(restaurantListAction(obj));
                        }
                        dispatch(restaurantListAction(obj));
                    }
                }).catch((error)=>{
                    const sources = error.response;
                    if (undefined !== sources) {
                        toast.error(sources.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.error('server error', {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    setTimeout(()=>{
                        window.location.href = "/"
                    },3000)
                })
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RestaurantList)

