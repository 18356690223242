import React, {Component} from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import poweredByImage from "../../images/railrestroand copy 3.svg";

export default class TopBar extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row top-bar mr-xl-0 pr-xl-0">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12 d-flex align-items-center">
                        <span className="back-icon"><KeyboardArrowLeftRounded
                            onClick={() => {
                                if (this.props.backUrl && this.props.selectedData) {
                                    this.props.history.push(this.props.backUrl, {query: this.props.selectedData});
                                } else {
                                    this.props.back.goBack();
                                }
                                // window.location.href = this.props.back;
                            }}/>
                        </span>
                        <h1 className="title m-0">{this.props.title}</h1>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-12">
                        <div className="poweredBy text-right">
                            <img src={poweredByImage} alt=""/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}