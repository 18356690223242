let initialState = {
    // trainListByName:[],
    restaurantList:null
};
const Home = (state = initialState, action) => {

    switch (action.type) {
        case 'RESTAURANT_LIST':
            return {...state, restaurantList: action.sources}
        default:
            return state
    }
};

export default Home;
