import {ssoInstance} from "gg-react-utilities";
import Config from "../Config";

export const getTrainList = input => {
    return ssoInstance.get(Config.API_URL + "train/search/" + input);
};

export const getTrainRouteList = input => {
    return ssoInstance.get(Config.API_URL + "train/route/" + input);
};

export const getOutletByTrainName = (stationCode) => {
    return ssoInstance.get(Config.API_URL + 'outlets/list/' + stationCode)
};

export const getOrderList = (status) => {
    return ssoInstance.get(Config.API_URL + 'order?status=' + status)
};

export const getFoodMenu = (outletId) => {

    return ssoInstance.get(Config.API_URL + 'outlets/menu/' + outletId)
};

export const getCartItems = (param) => {

    return ssoInstance.post(Config.API_URL + 'pre_order', param)
};

export const getPlaceOrder = (obj) => {
    return ssoInstance.post(Config.API_URL + 'place/order', obj)
};

export const cancelOrderRequest = (orderId) => {
    return ssoInstance.get(Config.API_URL + "order/" + orderId + "/cancellation_request")
};