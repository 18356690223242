import React, {Component} from "react";
import TopBar from "../core/components/TopBar";
import DeliveryContainer from "./container/DeliveryContainer";
import {Auth} from "gg-react-utilities";

export default class CheckOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    nextPage = (route, response) => {
        this.props.history.push(route, {
            query: response
        });
    };

    componentWillMount() {
        if (!Auth.isLoggedIn()) {
            Auth.login(true)
        }
    }

    render() {
        let data = JSON.parse(localStorage.getItem('preOrderData'));
        return (
            <React.Fragment>
                <div className="container">
                    <TopBar back={this.props.history} title="Delivery Detail"/>
                    <div className="clearfix"/>
                    <div className="delivery-details mb-5 pb-5">
                        <DeliveryContainer
                            {...this.props}
                            back={this.props.history}
                            pnr={this.props.location.state !== undefined ? this.props.location.state.query.pnr : data.pnr}
                            train_no={this.props.location.state !== undefined ? this.props.location.state.query.train_no : data.train_no}
                            train_name={this.props.location.state !== undefined ? this.props.location.state.query.train_name : data.train_name}
                            station={this.props.location.state !== undefined ? this.props.location.state.query.station : data.station}
                            station_code={this.props.location.state !== undefined ? this.props.location.state.query.station_code : data.station_code}
                            outletId={this.props.location.state !== undefined ? this.props.location.state.query.outletId : data.outletId}
                            station_name={this.props.location.state !== undefined ? this.props.location.state.query.station_name : data.station_name}
                            doj={this.props.location.state !== undefined ? this.props.location.state.query.doj : data.doj}
                            restaurant_name={this.props.location.state !== undefined ? this.props.location.state.query.restaurant_name : data.restaurant_name}
                            cartItem={this.props.location.state !== undefined ? this.props.location.state.query.cartItem : data.cartItem}
                            delivery_charge={this.props.location.state.query.delivery_charge !== undefined ? this.props.location.state.query.delivery_charge : data.delivery_charge}
                            nextPage={(route, param) => {
                                this.nextPage(route, param)
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
