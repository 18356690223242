import React, {Component} from "react";
import {Button, Position, Toaster} from "@blueprintjs/core";
import {Intent} from "@blueprintjs/core";
import {Auth} from "gg-react-utilities";

export default class DeliveryComponent extends Component {
    constructor(props) {
        super(props);
        this.handleChangePNR = this.handleChangePNR.bind(this);
        this.handleChangeFNM = this.handleChangeFNM.bind(this);
        this.handleChangeLNM = this.handleChangeLNM.bind(this);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            mobileNo: "",
            altMobileNo: "",
            pnr: this.props.pnr,
            remarks: "",
            payNowStatus: false,
            loading: false,
            loadingStatus: false,
            error: {
                firstName: "",
                lastName: "",
                mobileNumber: "",
                altMobileNumber: "",
                email: "",
                pnr: ""
            }
        };
    }

    componentDidMount() {
        if (Auth.isLoggedIn()) {
            this.setState({
                firstName: Auth.user().fname ? Auth.user().fname : "",
                lastName: Auth.user().lname ? Auth.user().lname : "",
                email: Auth.user().email ? Auth.user().email : "",
                mobileNo: Auth.user().mobile ? Auth.user().mobile : ""
            })
        }
    }

    handleChangeFNM(event) {
        let value = event.target.value;
        const re = /^[a-zA-Z ]*$/;
        if (!re.test(value)) {
            this.setState({
                error: {
                    firstName: "First Name allow only alphabets.", intent: Intent.DANGER
                }
            });
            return false;
        } else {
            this.setState({
                error_status: false,
                error: {
                    firstName: false
                },
                firstName: value
            })
        }
    }

    handleChangeLNM(event) {
        let value = event.target.value;
        const re = /^[a-zA-Z ]*$/;
        if (!re.test(value)) {
            this.setState({
                error: {
                    lastName: "Last Name allow only alphabets.", intent: Intent.DANGER
                }
            });
            return false;
        } else {
            this.setState({
                error_status: false,
                error: {
                    lastName: false
                },
                lastName: value
            })
        }
    }

    handleChangePNR(event) {
        let value = event.target.value;
        const re = /^[0-9\b]+$/;
        if (event.target.value.length > 0) {
            if (re.test(value)) {
                if (!/^\d{10}$/.test(event.target.value)) {
                    this.setState({
                        pnr: event.target.value,
                        error: {
                            pnr: "Please enter valid 10 digit PNR number!", intent: Intent.DANGER
                        }
                    });
                    return false;
                } else {
                    this.setState({
                        error_status: false,
                        error: {
                            pnr: false
                        },
                        pnr: value
                    })
                }
            }
        } else {
            this.setState({
                pnr: ""
            });
        }
    }

    checkout() {
        this.setState({loadingStatus: true});
        let error = "";
        let pnr = this.state.pnr ? this.state.pnr.trim() : '';
        let remarks = this.state.remarks.trim();
        let shipping_firstname = this.state.firstName.trim();
        let shipping_lastname = this.state.lastName.trim();
        let shipping_email = this.state.email.trim();
        let shipping_mobile = this.state.mobileNo.trim();
        let shipping_alt_mobile = this.state.altMobileNo.trim();

        if (shipping_firstname.trim() === "") {
            this.setState({
                error_status: true,
                loadingStatus: false,
                firstName: shipping_firstname,
                error: {
                    firstName: "Please enter your first name.", intent: Intent.DANGER
                }
            });
            error = error + " ,firstName";
            return false;
        } else if (shipping_firstname.trim() !== "" && !/[a-zA-Z ]+/.test(shipping_firstname.trim())) {
            this.setState({
                error_status: true,
                loadingStatus: false,
                firstName: shipping_firstname,
                error: {
                    firstName: "Please enter valid first name.", intent: Intent.DANGER
                }
            });
            error = error + " ,firstName";
            return false;
        } else {
            this.setState({
                error_status: false,
                loadingStatus: true,
                error: {
                    firstName: false
                },
                firstName: shipping_firstname,
            });
        }

        if (shipping_lastname.trim() === "") {
            this.setState({
                error_status: true,
                loadingStatus: false,
                lastName: shipping_lastname,
                error: {
                    lastName: "Please enter your last name.", intent: Intent.DANGER
                }
            });
            error = error + " ,lastName";
            return false;
        } else if (shipping_lastname.trim() !== "" && !/[a-zA-Z ]+/.test(shipping_lastname.trim())) {
            this.setState({
                error_status: true,
                loadingStatus: false,
                lastName: shipping_lastname,
                error: {
                    lastName: "Please enter valid last name.", intent: Intent.DANGER
                }
            });
            error = error + " ,lastName";
            return false;
        } else {
            this.setState({
                error_status: false,
                loadingStatus: true,
                error: {
                    lastName: false
                },
                lastName: shipping_lastname,
            });
        }

        if (shipping_mobile.trim() === "") {
            this.setState({
                error_status: true,
                loadingStatus: false,
                mobileNo: shipping_mobile,
                error: {
                    mobileNo: "Please enter mobile number.", intent: Intent.DANGER
                }
            });
            error = error + " ,mobileNumber";
            return false;
        } else if (shipping_mobile.trim() !== "" && !/^\d{10}$/.test(shipping_mobile.trim())) {
            this.setState({
                error_status: true,
                loadingStatus: false,
                mobileNo: shipping_mobile,
                error: {
                    mobileNo: "Please enter valid mobile number.", intent: Intent.DANGER
                }
            });
            error = error + " ,mobileNumber";
            return false;
        } else {
            this.setState({
                error_status: false,
                loadingStatus: true,
                error: {
                    mobileNo: false
                },
                mobileNo: shipping_mobile,
            });
        }

        if (shipping_alt_mobile.trim() !== "" && !/^\d{10}$/.test(shipping_alt_mobile.trim())) {
            this.setState({
                error_status: true,
                loadingStatus: false,
                altMobileNo: shipping_alt_mobile,
                error: {
                    altMobileNo: "Please enter valid alternate mobile number.", intent: Intent.DANGER
                }
            });
            error = error + " ,altMobileNumber";
            return false;
        } else {
            this.setState({
                error_status: false,
                loadingStatus: true,
                error: {
                    altMobileNo: false
                },
                altMobileNo: shipping_alt_mobile,
            });
        }

        if (shipping_email.trim() === "") {
            this.setState({
                error_status: true,
                loadingStatus: false,
                email: shipping_email,
                error: {
                    email: "Please enter email id.", intent: Intent.DANGER
                }
            });
            error = error + " ,email";
            return false;
        } else if (shipping_email.trim() !== "" && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(shipping_email)) {
            this.setState({
                error_status: true,
                loadingStatus: false,
                email: shipping_email,
                error: {
                    email: "Please enter valid email id.", intent: Intent.DANGER
                }
            });
            error = error + " ,email";
            return false;
        } else {
            this.setState({
                error_status: false,
                loadingStatus: true,
                error: {
                    email: false
                },
                email: shipping_email,
            });
        }

        if (pnr.trim() === "") {
            this.setState({
                error_status: true,
                loadingStatus: false,
                pnr: pnr,
                error: {
                    pnr: "Please enter PNR Number.", intent: Intent.DANGER
                }
            });
            error = error + " ,pnr";
            return false;
        } else if (pnr.trim() !== "" && !/^\d{10}$/.test(pnr.trim())) {
            this.setState({
                error_status: true,
                loadingStatus: false,
                pnr: pnr,
                error: {
                    pnr: "Please enter valid PNR Number.", intent: Intent.DANGER
                }
            });
            return false;
        } else {
            this.setState({
                error_status: false,
                loadingStatus: true,
                error: {
                    pnr: false
                },
                pnr: pnr,
            });
        }

        if (error === "") {
            this.setState({
                payNowStatus: true
            });
            let response = {
                pnr_no: pnr,
                station_code: this.props.station_code,
                outlet_id: this.props.outletId,
                remarks: remarks,
                items: this.props.cartItem,
                shipping_firstname: shipping_firstname,
                shipping_lastname: shipping_lastname,
                shipping_email: shipping_email,
                shipping_mobile: shipping_mobile,
                shipping_alt_mobile: shipping_alt_mobile,
                delivery_charge: this.props.delivery_charge
            };
            this.props.getPlaceOrder(response).then((res) => {
                const sources = res.data;
                if (sources.status === true) {
                    const PayUBiz = sources.data.pg;
                    let form = document.createElement("form");
                    form.setAttribute("method", "POST");
                    form.setAttribute("action", PayUBiz.action_url);
                    for (let key in PayUBiz) {
                        let hiddenField = document.createElement("input");
                        hiddenField.setAttribute("type", "hidden");
                        hiddenField.setAttribute("name", key);
                        hiddenField.setAttribute("value", PayUBiz[key]);
                        form.appendChild(hiddenField);
                    }
                    document.body.appendChild(form);
                    form.submit();
                    this.setState({
                        res: res.sources
                    })
                } else {
                    this.setState({loadingStatus: false});
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: sources.message, intent: Intent.DANGER});
                }

            }).catch((error) => {
                const sources = error.response;
                this.setState({loadingStatus: false});
                if (sources.data.message === "Validation Error.") {
                    sources.data.error.map((res) => {
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: res, intent: Intent.DANGER});
                        return res
                    })
                } else {
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: sources.data.message, intent: Intent.DANGER});
                }
            });
        } else {

        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="row">
                    <div className="col-lg-12 col-12">
                        <div className="row">
                            <div
                                className="col-xl-10 col-lg-10 col-md-10 offset-xl-1 offset-lg-1 offset-md-1 justify-content-center">
                                <div className={this.state.loading ? "row bp3-skeleton" : "row"}>
                                    <div className="card-body">
                                        <div className="col-12 mb-4 mt-4 text-left p-0">
                                            <p className="bp3-text-xlarge text-dark">Enter details</p>
                                            <p className="f-12 text-dark"><span
                                                className="bp3-text-danger">Note:</span> fill required information.
                                            </p>
                                        </div>
                                        <form>
                                            <div className={!Auth.isLoggedIn() ? "row mt-5 disable" : "row mt-5"}>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <input type="text" className="bp3-input"
                                                                       value={this.state.firstName}
                                                                       placeholder="Enter First Name"
                                                                       required="*"
                                                                       onChange={this.handleChangeFNM}
                                                                />
                                                                {this.state.hasError && <span>Error</span>}
                                                                <span
                                                                    className={"text-danger mt-1"}>{this.state.error.firstName}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <input type="text" className="bp3-input"
                                                                       value={this.state.lastName}
                                                                       placeholder="Enter Last Name"
                                                                       onChange={this.handleChangeLNM}/>
                                                                <span
                                                                    className={"text-danger mt-1"}>{this.state.error.lastName}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <input type="number" className="bp3-input"
                                                                       value={this.state.mobileNo}
                                                                       placeholder="Enter Mobile Number"
                                                                       onChange={(e) => {
                                                                           this.setState({mobileNo: e.target.value})
                                                                       }}/>
                                                                <span
                                                                    className={"text-danger mt-1"}>{this.state.error.mobileNo}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <input type="number" className="bp3-input"
                                                                       value={this.state.altMobileNo}
                                                                       placeholder="Enter Alternate Mobile Number"
                                                                       onChange={(e) => {
                                                                           this.setState({altMobileNo: e.target.value})
                                                                       }}/>
                                                                <span
                                                                    className={"text-danger mt-1"}>{this.state.error.altMobileNo}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <input type="email" className="bp3-input"
                                                                       value={this.state.email}
                                                                       placeholder="Enter Email"
                                                                       onChange={(e) => {
                                                                           this.setState({email: e.target.value})
                                                                       }}/>
                                                                <span
                                                                    className={"text-danger mt-1"}>{this.state.error.email}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <input
                                                                    type="text"
                                                                    className={this.state.error.pnr ? 'bp3-input input-box input-error-border' : 'bp3-input input-box'}
                                                                    autoComplete="off"
                                                                    id="pnr-number"
                                                                    name="pnr-number"
                                                                    value={this.state.pnr ? this.state.pnr : ""}
                                                                    onChange={this.handleChangePNR}
                                                                    placeholder="Enter PNR Number"
                                                                    autoFocus
                                                                    maxLength={10}
                                                                    readOnly={this.props.pnr ? this.props.pnr : ""}
                                                                />
                                                                {/*<input type="number" className="bp3-input"*/}
                                                                {/*max={10}*/}
                                                                {/*value={this.state.pnr ? this.state.pnr : ""}*/}
                                                                {/*placeholder="Enter PNR Number"*/}
                                                                {/*readOnly={this.props.pnr ? true : false}*/}
                                                                {/*onChange={this.handleChangePNR}*/}
                                                                {/*// onChange={(e) => {*/}
                                                                {/*//     this.setState({pnr: e.target.value})*/}
                                                                {/*// }}*/}

                                                                {/*/>*/}
                                                                <span
                                                                    className={"text-danger mt-1"}>{this.state.error.pnr}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xl-12 col-12">
                                                    <div className="bp3-form-group">
                                                        <div className="bp3-form-content">
                                                            <div className="bp3-input-group">
                                                                <textarea className="bp3-input" rows="5" id="comment"
                                                                          value={this.state.remarks}
                                                                          placeholder="Any special suggestions"
                                                                          onChange={(e) => {
                                                                              this.setState({remarks: e.target.value})
                                                                          }}>
                                                                </textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="text-right">
                                    {Auth.isLoggedIn() ?
                                        <Button
                                            className="bp3-button bp3-large bp3-intent-primary bp3-text-medium"
                                            loading={this.state.loadingStatus === false ? false : true}
                                            onClick={() => {
                                                this.checkout()
                                            }}
                                        >Pay Now
                                        </Button>
                                        :
                                        <Button
                                            className="bp3-button bp3-large bp3-intent-primary bp3-text-medium"
                                            disabled={true}
                                        >Pay Now
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
