import {connect} from "react-redux";
import OrderHistoryComponent from "../components/OrderHistoryComponent";
import {getOrderList} from "../../core/actions/action";
import {orderListAction, cancelOrderListAction} from '../../core/actions';

const mapStateToProps = state => {
    return {
        orderList: state.Order.orderList,
        cancelOrderList: state.Order.cancelOrderList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getOrderHistoryList: (input) => {
            getOrderList(2)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(cancelOrderListAction(sources.data))
                    } else {
                        dispatch(cancelOrderListAction([]))
                    }
                });

            getOrderList(1)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(orderListAction(sources.data))
                    } else {
                        dispatch(orderListAction([]))
                    }
                });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderHistoryComponent);
