let initialState = {
    cartItemLists:null
};
const CartItemList = (state = initialState, action) => {

    switch (action.type) {
        case 'PRE_ORDER_CART_ITEMS':
            return {...state, cartItemLists: action.sources};
        default:
            return state
    }
};

export default CartItemList;