let initialState = {
    orderList: [],
    cancelOrderList: [],
    placeOrderData:[]
};
const Order = (state = initialState, action) => {

    switch (action.type) {
        case 'ORDER_LIST':
            return {...state, orderList: action.sources};
        case 'CANCEL_ORDER':
            return {...state, cancelOrderList: action.sources};
        case 'PLACE_ORDER':
            return {...state, placeOrderData: action.sources};
        default:
            return state
    }
};

export default Order;
