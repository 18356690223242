let initialState = {
    orderHistoryList: [],
    cancelOrderRequest:[]
};

const orderHistory = (state = initialState, action) => {
    switch (action.type) {
        case 'ORDER_HISTORY':
            return {...state, orderHistoryList: action.sources};
        case 'CANCEL_ORDER_REQUEST':
            return {...state, cancelOrderRequest: action.sources};
        default:
            return state
    }
};
export default orderHistory;