import {connect} from "react-redux";
import OrderHistory from "../components/OrderHistory";
import {getOrderList} from "../../core/actions/action";
import {orderListAction} from "../../core/actions";

const mapStateToProps = state => {
    return {
        orderList: state.Order.orderList
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getOrderList: (input) => {
            getOrderList()
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(orderListAction(sources.data))
                    } else {
                        dispatch(orderListAction([]))
                    }
                });
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderHistory);
