import React, {Component} from "react";
import {Button, Intent, Position, Toaster} from "@blueprintjs/core";
import {DateInput} from "@blueprintjs/datetime";
import {Calendar} from "@blueprintjs/icons";
import {toast} from 'react-toastify';
import _ from "lodash";
import {Async} from "react-select";
import Select from 'react-select';
import moment from "moment";

export default class SearchComponent extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangePNR = this.handleChangePNR.bind(this);
        this.state = {
            dateOfJourney: "",
            loaderStatus: false,
            selectedOption: null,
            route_list:[],
            trainViaName: "",
            trainViaPNR: "",
            code: "",
            outletType: "",
            activeTab: 0,
            isChecked: true,
            error: {
                trainViaPNR: "",
                trainViaName: "",
                dateOfJourney: ""
            },
            loadingStatus: false
        };
    }

    toggleChange = (e) => {
        let value = e.currentTarget.value;
        this.setState({
            value,
            isChecked: !this.state.isChecked,
            loadingStatus: false
        });
    };

    getTrainByName = (input, callback) => {
        if (input.length <= 2) {
            callback(null, {
                options: [],
                complete: true
            });
            return Promise.resolve({options: []});
        }

        this.props
            .getTrainList(input)
            .then(res => {
                const sources = res.data;
                if (sources.status === true) {
                    let trainList = [];
                    sources.data.map(resp => {
                        trainList.push({
                            code: resp.code,
                            label: resp.code + " / " + resp.name,
                            value: resp.name
                        });
                        return trainList;
                    });
                    return trainList;
                } else {
                    toast.error(sources.message);
                }

                callback(null, {
                    options: [],
                    complete: true
                });
                return Promise.resolve({options: []});
            })
            .then(json => {
                callback(json, {
                    options: json,
                    complete: true
                });
            })
            .catch(error => {
                callback(null, {
                    options: [],
                    complete: true
                });
                return Promise.resolve({options: []});
            });
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.restaurantList !== null) {
            this.setState({loaderStatus: false});
        } else {
            this.setState({loaderStatus: true});
        }
    }

    handleChangePNR(event) {
        let value = event.target.value;
        const re = /^[0-9\b]+$/;
        if (event.target.value.length > 0) {
            if (re.test(value)) {
                if (!/^\d{10}$/.test(event.target.value)) {
                    this.setState({
                        error_status: true,
                        trainViaPNR: event.target.value,
                        error: {
                            trainViaPNR: "Please enter valid 10 digit PNR number!", intent: Intent.DANGER
                        }
                    });
                    return false;
                } else {
                    this.setState({
                        error_status: false,
                        error: {
                            trainViaPNR: false
                        },
                        trainViaPNR: value
                    })
                }
            }
        } else {
            this.setState({
                trainViaPNR: ""
            });
        }
    }

    handleChange(date) {
        let formatDate = new Date(date);
        let month = formatDate.getMonth() + 1;
        this.setState({dateOfJourney: formatDate.getFullYear() + "-" + month + "-" + formatDate.getDate()})
    }

    handleChangeRoute = (selectedOption) => {
        this.setState({ selectedOption });
    }

    getTrainRoute(value) {
        this.props.getTrainRouteList(value).then((res) => {
            const sources = res.data;
            if (sources.status === true) {
                let result = [];
                sources.data.map((item) => {
                    if(this.state.selectedOption === '' || this.state.selectedOption === null) {
                        this.setState({
                            selectedOption:{
                                label: item.station_name,
                                value: item.station_code
                            }
                        });
                    }
                    return result.push({
                        label: item.station_name,
                        value: item.station_code
                    });
                });
                this.setState({route_list: result});
            } else {
                this.setState({
                    loadingStatus: false
                });
                Toaster.create({
                    position: Position.TOP,
                }).show({message: sources.message, intent: Intent.DANGER});
                return false;
            }
        }).catch((error) => {
            const sources = error.response;
            this.setState({
                loadingStatus: false
            });
            Toaster.create({
                position: Position.TOP,
            }).show({message: sources.message, intent: Intent.DANGER});
            return false;
        });
    }

    getRestaurant(type, value) {
        this.setState({
            loadingStatus: true
        });
        let searchObj = {};
        if (type === "train") {
            if (value.trim() === '' || value === null) {
                this.setState({
                    loadingStatus: false,
                    error_status: true,
                    trainViaName: value,
                    error: {
                        trainViaName: "Please enter Train number or name!", intent: Intent.DANGER
                    }
                });
                return false;
            } else if (this.state.dateOfJourney === "" || this.state.dateOfJourney === undefined) {
                this.setState({
                    error_status: true,
                    loadingStatus: false,
                    dateOfJourney: value,
                    error: {
                        dateOfJourney: "Please select date of journey", intent: Intent.DANGER
                    }
                });
                return false;
            } else if (this.state.selectedOption === "" || this.state.selectedOption === undefined || this.state.selectedOption == null) {
                this.setState({
                    error_status: true,
                    loadingStatus: false,
                    trainRoute: this.state.selectedOption,
                    error: {
                        trainRoute: "Please select train route", intent: Intent.DANGER
                    }
                });
                return false;
            } else {
                this.setState({
                    error_status: false,
                    error: {
                        trainViaName: false,
                        dateOfJourney: false,
                        trainRoute: false
                    },
                    trainViaName: value,
                    dateOfJourney: value,
                    trainRoute: this.state.selectedOption.value,
                    loadingStatus: true,
                });
                searchObj.train = value;
                searchObj.date = moment(this.state.dateOfJourney).format("YYYY-MM-DD");
                searchObj.trainCode = this.state.selectedOption.value;
            }
        } else if (type === "pnr") {
            if (value.trim() === "") {
                if (!/^\d{10}$/.test(value)) {
                    this.setState({
                        error_status: true,
                        trainViaPNR: value,
                        loadingStatus: false,
                        error: {
                            trainViaPNR: "Please enter PNR number!", intent: Intent.DANGER
                        }
                    });
                    return false;
                }
            } else if (value.trim() !== "" && value.toString().length !== 10) {
                this.setState({
                    error_status: true,
                    loadingStatus: false,
                    trainViaPNR: value,
                    error: {
                        trainViaPNR: "Please enter valid 10 digit PNR number!", intent: Intent.DANGER
                    }
                });
                return false;
            } else {
                searchObj.pnr = value;
            }
        }
        searchObj.type = type;
        var str = '?' + Object.keys(searchObj).map((key) => {
            return key + '=' + searchObj[key];
        }).join('&');

        let params = '';
        if (searchObj.type === "pnr") {
            params = searchObj.type + '/' + searchObj.pnr;
            this.setState({
                type: searchObj.type,
                pnr: searchObj.pnr
            })
        } else {
            params = searchObj.type + '/' + searchObj.train + '/' + searchObj.date + '/' + searchObj.trainCode;
            this.setState({
                doj: searchObj.date,
                train: searchObj.train,
                type: searchObj.type,
                trainCode: searchObj.trainCode
            })
        }

        this.props.getOutletByTrainName(params).then((res) => {
            const sources = res.data;
            if (sources.status === true) {
                this.setState({
                    loadingStatus: false
                });
                this.props.history.push({
                    pathname: '/restaurant',
                    search: str
                })
            } else {
                this.setState({
                    loadingStatus: false
                });
                Toaster.create({
                    position: Position.TOP,
                }).show({message: sources.message, intent: Intent.DANGER});
                return false;
            }
        }).catch((error) => {
            const sources = error.response;
            this.setState({
                loadingStatus: false
            });
            Toaster.create({
                position: Position.TOP,
            }).show({message: sources.data.message, intent: Intent.DANGER});
            return false;
        });
    }

    render() {
        let fromSearch = _.debounce((input, callback) => {
            this.getTrainByName(input, callback);
        }, 100);
        const { selectedOption } = this.state;
        return (
            <React.Fragment>
                <div className="search-section">
                    <div className="search-box">
                        <div className="row">
                            <div className="col-12">
                                <div className="row mb-2 no-gutters">
                                    <label className="bp3-control bp3-radio mr-4">
                                        <input
                                            type="radio"
                                            name="docs-radio-regular"
                                            value="0"
                                            onChange={this.toggleChange}
                                        />
                                        <span className="bp3-control-indicator tab-pane"/>
                                        Search by train number
                                    </label>
                                    <label className="bp3-control bp3-radio">
                                        <input
                                            type="radio"
                                            name="docs-radio-regular"
                                            value="1"
                                            checked={this.state.isChecked}
                                            onChange={this.toggleChange}
                                        />
                                        <span className="bp3-control-indicator tab-pane"/>
                                        Search by PNR
                                    </label>
                                </div>
                            </div>

                            <div className="railFormWrapper">
                            </div>
                            {this.state.value === "0" ? (
                                <React.Fragment>
                                    <div className="col-12">
                                        <form>
                                            <div className="row">
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 form-group">
                                                    <Async
                                                        autoFocus={true}
                                                        clearable={false}
                                                        multi={false}
                                                        value={this.state.trainViaName}
                                                        isLoading={true}
                                                        onChange={value => {
                                                            setTimeout(() => {
                                                                this.setState({
                                                                    code: value.code,
                                                                    trainViaName: value,
                                                                    selectedOption: ""
                                                                }, () => {
                                                                    this.getTrainRoute(this.state.code)
                                                                });
                                                            }, 10);
                                                        }}
                                                        valueKey="name"
                                                        labelKey="value"
                                                        Key="code"
                                                        loadOptions={(input, callback) =>
                                                            fromSearch(input, callback)
                                                        }
                                                        // onKeyPress={this.enterPressed.bind(this)}
                                                        // onFocus={this.searchTrainKeyPress}
                                                        className="text-left"
                                                        placeholder={"Enter Train Number / Name"}
                                                        searchPromptText={"Please enter 3 or More characters"}
                                                        backspaceRemoves={true}
                                                    />
                                                    <span
                                                        className={"text-danger mt-2"}>{this.state.error.trainViaName}</span>
                                                </div>
                                                <div
                                                    className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 form-group mt-s-2">
                                                    <DateInput
                                                        formatDate={date => date.toLocaleDateString()}
                                                        dateFormat={"DD-MM-YYYY"}
                                                        id={"doj"}
                                                        name="doj"
                                                        type="text"
                                                        placeholder="Date of Journey"
                                                        popoverProps={{position: Position.BOTTOM}}
                                                        onBlur={e => {
                                                        }}
                                                        onChange={(doj) => this.handleChange(doj)
                                                        }
                                                        minDate={new Date()}
                                                        parseDate={str => new Date(str)}
                                                    />
                                                    <span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>
                                                    <span
                                                        className={"text-danger mt-2"}>{this.state.error.dateOfJourney}</span>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 form-group">
                                                    <Select
                                                        value={selectedOption}
                                                        valueKey="value"
                                                        labelKey="label"
                                                        Key="station_code"
                                                        onChange={this.handleChangeRoute}
                                                        options={this.state.route_list}
                                                        // placeholder="Select boarding station"
                                                        className="text-left"
                                                    />
                                                    <span
                                                        className={"text-danger mt-2"}>{this.state.error.trainRoute}</span>
                                                </div>
                                                <div
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 text-right mt-s-4">
                                                    <Button
                                                        intent="primary"
                                                        loading={this.state.loadingStatus === false ? false : true}
                                                        text="Search" // className="btn-view bp3-button"
                                                        onClick={() => {
                                                            this.getRestaurant("train", this.state.code);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="col-12">
                                        <form>
                                            <div className="row">
                                                <div
                                                    className="bp3-input-group modifier col-xl-4 col-lg-4 col-md-4 col-sm-7 col-12">
                                                    <input
                                                        type="text"
                                                        className={this.state.error.trainViaPNR ? 'bp3-input input-box input-error-border' : 'bp3-input input-box'}
                                                        autoComplete="off"
                                                        id="pnr-number"
                                                        name="pnr-number"
                                                        value={this.state.trainViaPNR}
                                                        onChange={this.handleChangePNR}
                                                        placeholder="Enter 10 digit PNR Number"
                                                        autoFocus
                                                        maxLength={10}
                                                    />
                                                    <span
                                                        className={"text-danger mt-2"}>{this.state.error.trainViaPNR}</span>
                                                </div>
                                                <div
                                                    className="col-xl-8 col-lg-8 col-md-8 col-sm-5 col-12 text-right mt-s-4">
                                                    <Button
                                                        intent="primary"
                                                        loading={this.state.loadingStatus === false ? false : true}
                                                        text="Search"
                                                        className="btn-view bp3-button"
                                                        onClick={() => {
                                                            this.getRestaurant("pnr", this.state.trainViaPNR);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
