import React, {Component} from 'react';
import NoOrderFound from "../../images/No_order_Found.png";
import NoCancelOrderFound from "../../images/No_cancel_order_found.png";

export default class EmptyState extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.stateType
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            type: nextProps.stateType
        })
    }

    render() {
        let image = "";
        let message = "<b>Oh snap,</b> We can't find it.";

        if ("home" === this.state.type) {
            image = NoOrderFound;
        }

        if ("orderHistory" === this.state.type) {
            image = NoOrderFound;
        }

        if ("cancelOrderHistory" === this.state.type) {
            image = NoCancelOrderFound;
        }

        return (
            <React.Fragment>
                <div className="col-md-12 p-4 mt-5">
                    <div className={"mg-empty-state"}>
                        <div className="text-center">
                            <img src={image} className="mr-2 empty-img" alt="empty state"/>
                            <p className="mt-4 mb-4" dangerouslySetInnerHTML={{__html: message}}></p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}