import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { GgUtils, PackageConfigure} from 'gg-react-utilities';
import { packageConfigure } from './core/Config';
import Config from './core/Config';

if(Config.APP_ENV === "production") {
    GgUtils.sentryScripts(Config.REACT_APP_SENTRY_URL);
}
ReactDOM.render(
    <React.Fragment>
        <App />
        <PackageConfigure {...packageConfigure} />
    </React.Fragment>
    , document.getElementById('root'));
unregisterServiceWorker();
