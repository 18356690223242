import React, { Component } from "react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import moment from "moment";

class ViewOrder extends Component {
  handleOpen = () => this.setState({ isOpen: true });
  handleClose = () => this.setState({ isOpen: false });

  render() {
    const orderDetail = this.props.viewDetail;
    return (
      <React.Fragment>
        <Button
          small={true}
          intent="primary"
          text="View"
          className="bp3-button btn-view mr-2"
          onClick={this.handleOpen}
        />

        <Dialog
          onClose={this.handleClose}
          title="Order Detail"
          {...this.state}
          className="bp3-dialog-large"
        >
			<div className={Classes.DIALOG_BODY}>
				<div className="clearfix" />
				<div className="container" />
					<div className="">
					<div className="row">
						<div className="col-xl-12 col-12 pr-0">
							<div className="">
								<div className="col-xl-12 col-12">
									<div className="row mt-0">
										<div className="col-4 col-xl-4 pr-s-0">
											<div className="train-platform">
												Platform
												<p className="oh-sub">
													{orderDetail.outlet_details.outlet_station_name}
													(
													{orderDetail.outlet_details.outlet_station_code}
													)
												</p>
											</div>
											<div className="train-name">
												Restaurant
												<p className="oh-sub">
													{orderDetail.outlet_details.outlet_name}
												</p>
											</div>
										</div>
				
										<div className="col-4 col-xl-4 pr-s-0">
											<div className="train-platform">
												Train
												<p className="oh-sub">
													<p>
														{orderDetail.passenger_details.train_no} /
														{
														orderDetail.passenger_details
															.journey_to_from_code
														}
													</p>
												</p>
											</div>
											<div className="train-name">
												Journey Date
												<p className="oh-sub">
													<p>
														{orderDetail.passenger_details.journey_date}
													</p>
												</p>
											</div>
										</div>
				
										<div className="col-4 col-xl-4 text-right pl-s-1">
											<div className="train-platform">
												Order status
												<p
													className="status oh-sub"
													style={{
														color:
														orderDetail.order_status.order_status_color
													}}
													>
													{orderDetail.order_status.order_status_label}
												</p>
											</div>
											<div className="train-name">
												Booking Date
												<p className="oh-sub">
													{moment(orderDetail.order.created_at).format(
														"DD MMM YYYY"
													)}
												</p>
											</div>
										</div>
				
										<div className="col-12 col-xl-12 mt-4">
											<div className="row">
												{orderDetail.items.map((item, index) => {
												return (
													<React.Fragment key={item.id}>
														<div className="col-xl-3 col-sm-4 col-6">
															<div className="order-name">
																{item.item_name}
															</div>
														</div>
														<div className="col-xl-7 col-sm-5 hidden-xs">
															<div className="border"></div>
														</div>
														<div className="col-xl-2 col-sm-3 col-6 text-right">
															<div className="order-price">
																₹
																{(
																item.base_price * item.quantity
																).toFixed(2)}
															</div>
														</div>
													</React.Fragment>
												);
												})}
					
												{orderDetail.totals.map((total, index) => {
												return (
													<React.Fragment key={total.sort_order}>
														<div className="col-xl-3 col-sm-4 col-6">
															<div className="order-name">
																{total.code === "grand_total" ? (
																<b>{total.title}</b>
																) : (
																total.title
																)}
															</div>
														</div>
														<div className="col-xl-7 col-sm-5 hidden-xs">
															<div className="border"></div>
														</div>
														<div className="col-xl-2 col-sm-3 col-6 text-right">
															<div className="order-price">
																₹
																{total.code === "grand_total" ? (
																<b>{total.value}</b>
																) : (
																total.value
																)}
															</div>
														</div>
													</React.Fragment>
												);
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="mt-3 text-right">
								{orderDetail.is_invoice_downloadable === true ? (
									<a
									href={orderDetail.download_invoice_url}
									className="bp3-button bp3-small bp3-intent-primary btn-download"
									>
									Download
									</a>
								) : (
									""
								)}
								<button
									className="bp3-button bp3-small bp3-intent-danger btn-button-dialog"
									onClick={this.handleClose}
									>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ViewOrder;
