import {connect} from "react-redux";
import MenuList from '../components/MenuList';
import {restaurantFoodMenuAction, preOrderAction} from "../../core/actions";
import {getFoodMenu, getCartItems} from "../../core/actions/action";
import {Intent, Position, Toaster} from "@blueprintjs/core";

let obj = {
    menuLists: [],
    cartItems: []
};

const mapStateToProps = state => {

    return {
        foodMenuList: state.FoodMenuList.foodMenuList,
        cartItemLists: state.CartItemList.cartItemLists
    }
};

const mapDispatchToProps = dispatch => {

    return {
        getMenuByOutletId: (typeCode) => {
            dispatch(restaurantFoodMenuAction(null));
            return getFoodMenu(typeCode)
                .then((res) => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(restaurantFoodMenuAction(sources.data))
                    } else {
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: sources.message, intent: Intent.DANGER});
                        dispatch(restaurantFoodMenuAction(null));
                    }
                })
                .catch(error => {
                    const sources = error.response;
                    if (error.response !== undefined) {
                        if(422 === error.response.status) {
                            Object.keys(error.response.data.error).forEach((key)=>{

                                error.response.data.error[key].forEach((error)=>{
                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent: Intent.DANGER});

                                });
                            });
                        } else{
                            Toaster.create({
                                position: Position.TOP,
                            }).show({message: error.response.data.message, intent: Intent.DANGER});

                        }
                    }else {
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: 'Something went wrong', intent: Intent.DANGER});
                    }

                });
        },
        getCartItems: (param) => {
            dispatch(preOrderAction(null));
            return getCartItems(param)
                .then((res) => {
                    const sources = res.data;
                    return new Promise((resolve => resolve(sources)))
                }).catch((error) => {
                    const sources = error.response;
                    return new Promise(((resolve, reject) => reject(sources.data)))
                })
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuList)

