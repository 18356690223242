import React, {Component} from 'react';
import _ from 'lodash';
import {Auth} from "gg-react-utilities";

class MenuItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            items: []
        }
    }

    componentDidMount() {
        this.setState({
            items:this.props.items
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            items:nextProps.items
        })
    }

    handleCountChange = () => {
        if(!Auth.isLoggedIn()){
            Auth.login(true)
        } else {
            let filterData = _.filter(this.state.items,(items,index)=>{
                return items.count > 0 && items.count !== undefined;
            });
            this.props.onSelectFilterData(filterData);
        }
    };

    render() {

        if (!this.state.items.length === 0){
            return(null)
        }

        return this.state.items.map((item,key)=>{
            return(
                <tr key={key}>
                    <td>
                        <div className="food-name">
                            {item.item_name}
                            <p>{item.description ? item.description : ""}</p>
                        </div>
                    </td>
                    <td>
                        <div className="food-name">
                            ₹ {parseFloat(item.base_price).toFixed(2)}
                            <p className="oh-sub">Time: {item.opening_time} - {item.closing_time}</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <div className="clearfix"/>
                            <div className="bp3-control-group">
                                <button
                                    className="bp3-button bp3-decrease"
                                    onClick={()=>{
                                        let obj = this.state.items;
                                        let currentCount = obj[key].count!==undefined?obj[key].count:0;
                                        if (currentCount>0){
                                            obj[key].count = parseInt(currentCount) - 1;
                                            this.setState({
                                                items:obj
                                            },()=>{
                                                this.handleCountChange();
                                            })
                                        }
                                    }}
                                >
                                    <span className="bp3-icon bp3-icon-minus"/>
                                </button>
                                <div className="bp3-input-group bp3-input-counter-group">
                                    <input
                                        type="text"
                                        className="bp3-input bp3-input-counter text-center"
                                        value={item.count ? item.count : 0}
                                    />

                                </div>
                                <button
                                    className="bp3-button bp3-increase"
                                    onClick={()=>{
                                        let obj = this.state.items;
                                        let currentCount = obj[key].count!==undefined?obj[key].count:0;
                                        if (currentCount>=0){
                                            if (Auth.isLoggedIn()){
                                                obj[key].count = parseInt(currentCount) + 1;
                                            }
                                            this.setState({
                                                items:obj
                                            },()=>{
                                                this.handleCountChange();
                                            })
                                        }
                                    }}
                                >
                                    <span className="bp3-icon bp3-icon-plus"/>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            )
        })
    }
}

export default MenuItems;