import React, {Component} from 'react';
import {PageTitle} from 'gg-react-utilities';
import {Dialog} from "@blueprintjs/core";
import Success from "../images/success.svg";
import Fails from "../images/fail.svg";
import {Link} from "react-router-dom";

export default class index extends Component {
    constructor(props) {
        super(props);
    };

    componentDidMount() {
        localStorage.removeItem('preOrderData');
        localStorage.removeItem('added_order');
        setTimeout(() => {
            window.location.href = "/"
        }, 5000)
    }
    render() {
        let status = this.props.match.params.status;
        let orderId = this.props.match.params.orderID;
        let title = "";
        let message = "";
        let link = "";
        let image = "";

        if (status === "success") {
            title = "Your Order is Successfully Placed";
            message = "Order # " + orderId + " has been placed successfully and an invoice has been emailed to the customer.";
            link = <Link to="/orderhistory" className="pb-4">View Order History</Link>;
            image = Success;
        } else if (status === "failed") {
            title = "Your Order has been failed.";
            message = "Order # " + orderId + " You might receive a message from your bank that the payment could not be processed.";
            link = <Link to="/" className="pb-4">Go To Home</Link>;
            image = Fails;
        }

        return (
            <div className='container'>
                {/*<TopBar title={"Payment"} backClick={this.props.back}/>*/}
                <PageTitle title={'Payment - Global Garner'}/>
                <div className={"mh-100"}>
                    <Dialog
                        isOpen={true}
                        lazy={false}

                        className="bp3-dialog-large payment-status"
                        canOutsideClickClose={false}
                    >
                        <div className="mg-brand-payments">
                            <div className="">
                                <div className="">
                                    <div className="">
                                        <div className="paymentWrapper">
                                            <div className="upperPaymentWrapper"></div>
                                            <div className="middlePaymentWrapper">
                                                <div className="circleWrapper">
                                                    <div className="iconWrapper">
                                                        <img src={image} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8 lowerPaymentWrapper m-auto">
                                                <h3>{title}</h3>
                                                <p>{message}</p>
                                                {link}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}