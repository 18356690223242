import React, {Component} from "react";
import SearchContainer from "./container/SearchContainer";
import OrderHistoryContainer from "./container/OrderHistoryContainer";
import {Auth} from "gg-react-utilities";
import poweredByImage from "../images/railrestroand copy 3.svg";

export default class Home extends Component {
    componentDidMount() {
        localStorage.removeItem('preOrderData');
    }

    render() {
        return (
            <React.Fragment>
                <div className="top_bar_content">
                    <div className="container">
                        <div className="text-right pt-3">
                            <img src={poweredByImage} alt=""/>
                        </div>
                        <div className="display-container">
                            <div className="display-title">
                                <h1 className="sb-title">Finger licking food on wheels!</h1>
                                <h4 className="sb-mute-text">
                                    You can order delicious yummylicious food on the go while
                                    traveling in a train and get food delivered on your seat
                                </h4>
                            </div>
                            <div className="search-box p-3 col-xl-12">
                                <SearchContainer
                                    {...this.props}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"/>
                {Auth.isLoggedIn() ?
                    <div className="container">
                        <div className="bg-basic-order-history col-xl-12 pb-5 mb-5">
                            <OrderHistoryContainer/>
                        </div>
                    </div>
                    : ""}
            </React.Fragment>
        );
    }
}
