import React, {Component} from "react";
import CartItem from "./CartItem";
import _ from 'lodash';
import Loader from "../../core/components/Loader";
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {numberFormat} from '../../core/components/CommonFunction';

export default class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            cartItems: [],
            cartItemLists: [],
            cartItemsPreOrder: [],
            loading: true
        };
    }

    increment = () => {
        this.setState({
            value: this.state.value + 1
        });
    };

    decrement = () => {
        if (this.state.value < 1) {
            this.setState({
                value: 0
            });
        } else {
            this.setState({
                value: this.state.value - 1
            });
        }
    };

    componentWillMount() {
        if (this.props.cartItemLists !== "") {
            this.setState({
                cartItems: this.props.cartItemsPreOrder,
                cartTotals: this.props.cartItemsPreOrder.totals,
                loading: false
            });
        } else {
            this.setState({
                cartItems: [],
                cartTotals: [],
                loading: false
            });
        }
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.cartItemLists) {
            this.setState({
                cartItems: nextProps.cartItemLists,
                cartTotals: nextProps.cartItemLists.totals,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    checkoutDetails(response) {
        this.props.nextPage("/checkoutDetails", response);
    }

    handleChange = (itemsValue) => {
        let cartFinalItem = [];
        itemsValue.map((item) => {
            cartFinalItem.push({
                item_id: item.item_id,
                quantity: item.quantity
            });
        });
        let param = {
            outlet_id: this.props.outletId,
            items: cartFinalItem,
            delivery_charge: this.props.delivery_charge
        };
        this.setState({
            loadingStatus: true
        });
        return this.props.getCartItems(param)
            .then(() => {
                this.setState({
                    loadingStatus: false
                });
                this.props.onCartUpdate(cartFinalItem);
                return new Promise(((resolve, reject) => resolve(true)))
            }).catch(() => {
                this.props.backMenuUrl();
                return new Promise(((resolve, reject) => reject(false)))
            });
    };

    onNextPage = () => {
        let response = {
            outletId: this.props.outletId,
            restaurant_name: this.props.restaurant_name,
            doj: this.props.doj,
            pnr: this.props.pnr,
            train_no: this.props.train_no,
            train_name: this.props.train_name,
            station: this.props.station,
            station_name: this.props.station_name,
            station_code: this.props.station_code,
            delivery_charge: this.props.delivery_charge,
            cartItem: []
        };
        this.props.nextPage('/menu' + this.props.outletId, response)
    };

    render() {
        return (
            <React.Fragment>
                <div className="clearfix"/>
                <div className="card border mt-4 cart-list mb-5 ">
                    <div className="card-body pt-0">
                        {
                            this.state.loading === true ?
                                <div className="mb-2">
                                    <div className="bp3-skeleton"
                                         style={{width: "100%", height: "150px"}}>&nbsp;</div>
                                </div>
                                :
                                <div className="row">
                                    <CartItem
                                        dataSources={this.state.cartItems}
                                        onGetSelectData={this.handleChange}

                                    />
                                    <div className="col-xl-4 col-12 pr-0">
                                        <div className="card border-0" style={{background: "#F8FCFF"}}>
                                            <div className="card-body p-4 text-left">
                                                <div className="col-xl-12 col-12">
                                                    <p className="mb-5 mt-2 bp3-text-xlarge font-weight-normal text-dark cart-title">
                                                        Total payable amount
                                                    </p>
                                                    <div className="clearfix"/>
                                                    {this.state.loadingStatus === true ?
                                                        <div className="text-center">
                                                            <Loader/>
                                                        </div>
                                                        :
                                                        <div className="row">
                                                            {this.state.cartItems !== "" ?
                                                                this.state.cartItems.items.map((item, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div className="col-md-8">
                                                                                <p className="mb-4 text-left mt-2">
                                                                                    <small>{item.item_name.toLowerCase()} &nbsp;&#xd7;&nbsp; {item.quantity}</small>
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <p className="mb-4 text-right mt-2">
                                                                                    <small>{numberFormat(item.quantity * Number(item.base_price))}</small>
                                                                                </p>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                }) :
                                                                <div>
                                                                    <h1>No Record Found</h1>
                                                                </div>
                                                            }
                                                            {this.state.cartTotals !== "" ?
                                                                this.state.cartTotals.map((total, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <div
                                                                                className={total.title === "Grand Total" ? "col-md-8 border-top" : "col-md-8"}>
                                                                                <p className="mb-4 text-left mt-2">
                                                                                    <small>{total.title === "Grand Total" ?
                                                                                        <b>{total.title}</b> : total.title}</small>
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className={total.title === "Grand Total" ? "col-md-4 border-top" : "col-md-4"}>
                                                                                <p className="mb-4 text-right mt-2">
                                                                                    {total.title === "Grand Total" ?
                                                                                        <small>{total.type}<b>{numberFormat(total.value)}</b>
                                                                                        </small> :
                                                                                        <small>{total.type} {numberFormat(total.value)}</small>}
                                                                                </p>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )
                                                                }) :
                                                                <div>
                                                                    <h1>No Record Found</h1>
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-xl-12 col-12 mt-5 pull-right">
                                                    <button
                                                        className="bp3-button bp-small bp3-intent-primary pull-right"
                                                        onClick={() => {
                                                            let total = _.find(this.state.cartTotals, {'code': 'sub_total'});
                                                            if (this.props.minimum_order < total.value) {
                                                                let cartItem = [];
                                                                this.state.cartItems.items.map((item) => {
                                                                    cartItem.push({
                                                                        item_id: item.item_id,
                                                                        quantity: item.quantity
                                                                    });
                                                                    return cartItem
                                                                });
                                                                let response = {
                                                                    outletId: this.props.outletId,
                                                                    restaurant_name: this.props.restaurant_name,
                                                                    doj: this.props.doj,
                                                                    pnr: this.props.pnr,
                                                                    train_no: this.props.train_no,
                                                                    train_name: this.props.train_name,
                                                                    station: this.props.station,
                                                                    station_name: this.props.station_name,
                                                                    station_code: this.props.station_code,
                                                                    delivery_charge: this.props.delivery_charge,
                                                                    cartItem: cartItem
                                                                };
                                                                this.checkoutDetails(response);
                                                            } else {
                                                                Toaster.create({
                                                                    position: Position.TOP,
                                                                }).show({
                                                                    message: "Add more items to cart!",
                                                                    intent: Intent.DANGER
                                                                });
                                                            }
                                                        }}>Checkout
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
