const APP_ENV = 'PRODUCTION'; // local,DEV,PRODUCTION
const APP_EXTENSION = '.com';
const MAINTENANCE_MODE = false;

const config = {
    ENV                 : APP_ENV,
    IS_MAINTENANCE_MODE : MAINTENANCE_MODE,
    EXTENSION           : APP_EXTENSION,

    APP_URL             : 'https://railbhoj.globalgarner.com',
    API_URL             : 'https://railbhoj-api.globalgarner.com/api/',
    ACCOUNT_API_URL     : 'https://accounts.globalgarner.com/api/',

    DASHBOARD_URL       : 'https://dashboard.globalgarner.com/',
    USER_PROFILE_URL    : 'https://dashboard.globalgarner.com/profile',
    STORE_API_URL       : 'https://store-api.globalgarner.com/v1.0.3/',
    MEGABRAND_API_URL   : 'https://mega-brands-api.globalgarner.com/user/v1.0.3/'
};

if(APP_ENV === 'local'){

    config.APP_URL              = 'http://localhost:3000/';
    config.API_URL              = 'https://railbhoj-api.globalgarner.in/api/';
    config.ACCOUNT_API_URL      = 'https://accounts.globalgarner.in/';

    config.DASHBOARD_URL        = 'https://dashboard.globalgarner.in/';
    config.USER_PROFILE_URL     = 'https://dashboard.globalgarner.in/profile';
    config.STORE_API_URL        = 'https://store-api.globalgarner.in/v1.0.3/';
    config.MEGABRAND_API_URL    = 'https://mega-brands-api.globalgarner.in/user/v1.0.3/';
}

if(APP_ENV === 'production'){

    config.APP_URL              = 'https://railbhoj.globalgarner.com';
    config.API_URL              = 'https://railbhoj-api.globalgarner.com/api/';
    config.ACCOUNT_API_URL      = 'https://accounts.globalgarner.com/';

    config.DASHBOARD_URL        = 'https://dashboard.globalgarner.com/';
    config.USER_PROFILE_URL     = 'https://dashboard.globalgarner.com/profile';
    config.STORE_API_URL        = 'https://store-api.globalgarner.com/v1.0.3/';
    config.MEGABRAND_API_URL    = 'https://mega-brands-api.globalgarner.com/user/v1.0.3/';
}

if(APP_ENV === 'DEV'){

    config.APP_URL              = 'https://railbhoj.globalgarner.in';
    config.API_URL              = 'https://railbhoj-api.globalgarner.in/api/';
    config.ACCOUNT_API_URL      = 'https://accounts.globalgarner.in/';

    config.DASHBOARD_URL        = 'https://dashboard.globalgarner.in/';
    config.USER_PROFILE_URL     = 'https://dashboard.globalgarner.in/profile';
    config.STORE_API_URL        = 'https://store-api.globalgarner.in/v1.0.3/';
    config.MEGABRAND_API_URL    = 'https://mega-brands-api.globalgarner.in/user/v1.0.3/';
}

export const packageConfigure={
    APP_URL             : config.APP_URL,
    ACCOUNT_API_URL     : config.ACCOUNT_API_URL,
    DASHBOARD_URL       : config.DASHBOARD_URL,
    USER_PROFILE_URL    : config.USER_PROFILE_URL,
    STORE_API_URL       : config.STORE_API_URL,
    MEGABRAND_API_URL   : config.MEGABRAND_API_URL,
    ADMIN_API_URL       : config.ADMIN_API_URL,
    APP_ENV             : config.ENV,
    Extension           : config.EXTENSION,
};

config.REACT_APP_SENTRY_URL='https://1f0312dd4fa442cdbcf50284e2c7c006@sentry.io/1413308';

export default config;
