import React, {Component} from "react";
import TopBar from "../core/components/TopBar";
import RestaurantList from "./container/RestaurantList";

export default class Restaurant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    onSearch(parameter) {
        setTimeout(() => {
            this.setState({
                loading: false,
                orderingFood: parameter,
                isSearch: true,
                searchParaObj: parameter
            });
        }, 2000);
    }

    nextPage = (route, param) => {
        let pathName = this.props.history.location.pathname;
        this.props.history.push(route, {
            query: param,
            path: pathName
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="container mb-5">
                    <TopBar back={this.props.history} title="Choose restaurant"/>
                    <RestaurantList
                        {...this.props}
                        onSearch={parameter => {
                            this.setState(() => {
                                this.onSearch(parameter);
                            });
                        }}
                        nextPage={(route, param) => {
                            this.nextPage(route, param)
                        }}
                        searchObj={this.state.searchParaObj}
                    />
                </div>
            </React.Fragment>
        );
    }
}
