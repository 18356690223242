import {connect} from "react-redux";
import DeliveryComponent from "../components/DeliveryComponent";
import {getPlaceOrder} from "../../core/actions/action";

const mapStateToProps = state => {
    return {
        placeOrder:state.Order.placeOrderData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getPlaceOrder:(obj)=>{
            return getPlaceOrder(obj);
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryComponent);
